@import "~scss/imports";
:root {
  --placesWidth: 100%;

  @include breakpoint(lg) {
    --placesWidth: 450px;
  }
  @include breakpoint(xl) {
    --placesWidth: 550px;
  }
}
.element {
  h1 {
    @extend %h1;
    font-size: 2rem;
    color: var(--pink);
    margin-bottom: 2rem;
    @include breakpoint(lg) {
      position: fixed;
      left: calc(var(--placesWidth) + 2 * var(--padding));
      z-index: 999;
      top: var(--padding);
    }
  }
}
.wrapper {
  background-color: var(--black);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: calc(var(--padding) + 0px);
}
.map {
  display: none;

  @include breakpoint(lg) {
    display: block;
    position: fixed;
    width: calc(100% - var(--placesWidth) - 2 * var(--padding));
    height: calc(100vh - 2 * var(--padding) - 73px);
    margin: var(--padding);
    box-sizing: content-box;
    top: 1px;
    left: var(--placesWidth);
    border-left: 1px solid var(--white);
  }
}

.border {
  display: none;

  position: fixed;

  margin: var(--padding);
  border: 1px solid var(--white);
  height: calc(100vh - 2 * var(--padding));
  width: calc(100vw - 2 * var(--padding) - 2px);
  box-sizing: content-box;
  top: 200px;
  left: 0;
  z-index: 1;
  pointer-events: none;
  // background-color: rgba(0, 0, 200, 0.5);

  &:before,
  &:after {
    content: "";
    width: 100vw;
    height: var(--padding);
    background-color: var(--black);
    position: fixed;
    top: -1px;
    left: 0;
  }
  &:after {
    top: auto;
    bottom: -2px;
  }

  @include breakpoint(md) {
    display: block;
    width: calc(100vw - 2 * var(--padding) - 2px);
    top: 0;
  }

  @include breakpoint(xl) {
    width: calc(100vw - 2.5 * var(--padding) - 2px);
  }
}
.footer {
  border-top: 1px solid var(--white);

  @include breakpoint(md) {
    position: fixed;
    bottom: -1px;
    left: 1px;
    width: calc(100vw - 2.5 * var(--padding) - 1px);
    margin: var(--padding);
  }
}
.places {
  p {
    @extend %p;
    line-height: 1.4;
    padding: 0 2rem 2rem;
    border-bottom: 1px solid var(--white);
    margin: 0 calc(-1 * var(--padding)) 3rem;
    @include breakpoint(lg) {
      padding: 0;
      border: none;
      margin: 2rem 2rem 2rem 2.5rem;
    }
  }
  color: var(--white);
  position: relative;
  // max-width: calc(var(--placesWidth) - 1px var(--padding) / 2);
  max-width: var(--placesWidth);
  box-sizing: content-box;
  margin: var(--padding);
  padding: var(--padding);
  border: 1px solid var(--white);
  top: 20px;
  ul {
    overflow: hidden;
    padding-bottom: 8rem;
    padding-left: 0;
  }

  @include breakpoint(md) {
    margin: 2px var(--padding) var(--padding);
    border: none;
    padding-top: 8rem;
    top: 0;
    box-sizing: border-box;
  }
  .logo {
    display: none;
    @include breakpoint(md) {
      display: block;
    }
  }
}
.logo {
  width: 100%;
  margin-left: 0;
  :global(.logo) {
    @include breakpoint(md) {
      left: var(--padding);
      margin: 0;
    }
  }
}
.mobileLogo {
  @include breakpoint(md) {
    display: none;
  }
}

.marker {
  position: absolute;
  width: 20px;
  height: 20px;
  text-decoration: none;
  border-radius: 50%;
  border: 2px solid var(--pink);
  cursor: pointer;
  z-index: 5000;
  transition: 0.5s;
  background-color: var(--black);
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: var(--pink);
    font-size: 1em;
    font-weight: 500;
    transition: 0.5s;
  }
  z-index: 1;
  &:hover,
  &.selected {
    z-index: 10;
    background-color: var(--pink);
    span {
      color: var(--black);
    }
  }
  &.selected {
    transform: scale(1.25);
  }
}
.itemSelected {
  h3 {
    color: var(--pink);
  }
  h3.granteeWrap {
    color: var(--yellow);
  }
}
.listItem {
  color: #fff;
  // font-family: "Adieu";
  font-size: 1rem;
  margin: 0;
  padding: 0 0 0 3rem;
  list-style: none;
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  font-family: "Good Sans";
  line-height: 1.5;
  .social {
    margin-top: 1.25rem;
    font-size: 1.4rem;
  }
  a {
    transition: 0.5s;
    &:hover {
      color: var(--pink);
    }
  }
  .link,
  .directions {
    a {
      text-decoration: none;
      border-bottom: 1px solid var(--white);
      padding: 0.5rem 0 0.25rem;

      display: inline-block;
      &:hover {
        border-color: transparent;
      }
    }
  }

  h3 {
    margin: 0;
    font-family: "Adieu";
    text-transform: uppercase;
    font-size: 1rem;
    position: relative;
    span {
      position: absolute;
      width: 2.25rem;
      left: -3rem;
      text-align: right;
    }
  }
  button {
    border: none;
    cursor: pointer;
    background-color: transparent;
    color: inherit;
    text-align: left;
    padding: 0;
    margin: 0 0 1rem;

    &:focus,
    &:active {
      border: 0;
      outline: none;
    }
  }

  a {
    color: white;
    & + a {
      margin-left: 1rem;
    }
  }
  @include breakpoint(xl) {
    h3 {
      font-size: 1.25rem;
    }
    font-size: 1rem;
  }
}

.toggle {
  position: fixed;
  z-index: 1000;
  top: var(--padding);
  left: calc(59px + var(--padding));

  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--black);
    text-decoration: none;
    font-family: "Adieu";
    text-transform: uppercase;

    color: white;

    border: 1px solid white;

    font-size: 0.8rem;
    height: 28px;
  }

  width: 200px;
  @include breakpoint(md) {
    left: calc(180px + var(--padding));
    a {
      font-size: 1rem;
      height: 59px;
    }
  }
  @include breakpoint(lg) {
    width: calc(var(--placesWidth) - 180px);
    border: none;
    left: calc(180px + var(--padding));
    a {
      border: none;
      border-top: 1px solid white;
      border-bottom: 1px solid white;
    }
  }
}
// .social {
//   transform: scale(1.25);
// }
.granteeMarker {
  border-color: var(--yellow);
  span {
    color: var(--yellow);
  }
  &.selected,
  &:hover {
    background-color: var(--yellow);
  }
}
.grantee {
  color: var(--yellow);
  font-family: "Good Sans";
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  margin: 1rem 0 0;
}
