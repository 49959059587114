@import "~scss/imports";

.intro {
  padding-bottom: 3rem;
  position: relative;
  h1 {
    color: var(--pink);
  }
  p {
    font-size: 1rem;
    line-height: 1.375;
    font-family: "Good Sans";
  }
  &:after {
    content: "";
    width: calc(100% + 2 * var(--padding));
    display: block;
    margin: 0 calc(-1 * var(--padding));
    border-bottom: 1px solid var(--white);
    position: absolute;
    bottom: 0;
  }
  a {
    color: var(--pink) !important;
  }
}
.element {
  margin: calc(1 * var(--padding)) 0;
  ul {
    width: 100%;
    // display: flex;
    list-style: none;

    @include breakpoint(sm) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
    }
    @include breakpoint(lg) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  a {
    text-decoration: none;
  }
  :global(.stories--updating) {
    opacity: 0.5;
    pointer-events: none;
  }
}
.infinite {
  width: 100%;
  text-align: center;
  @extend %h4;
}
.results {
  @extend %h4;
  margin: var(--padding) 0;
  text-transform: uppercase;
  span {
    color: var(--pink);
  }
}

.filterToggle {
  border-bottom: 1px solid;
  margin: 0 calc(-1 * var(--padding));

  button {
    margin: 1rem 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    background-color: transparent;
    border: 0;
    color: currentColor;
    padding: 0 var(--padding);
    @extend %h3;
  }
  button.closeFilters {
    margin-top: 0;
    width: 100%;
    position: fixed;
    z-index: 100001;
    top: 0;
    right: 0;
    padding: var(--padding);

    border: none;
    width: 100%;
    background-color: var(--black);

    &:after {
      content: "";
      width: 16px;
      height: 3px;
      background-color: var(--white);
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      right: 1rem;
    }
  }
  @include breakpoint(lg) {
    display: none;
  }
}
