.colorOpt {
  display: flex;
  // width: 50px;
  // height: 50px;
  // border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-family: "Adieu";
  font-size: 24px;
  // border: 1px solid var(--black);
  position: relative;
  color: var(--black);
  background-color: transparent !important;
  // &:global(bg--black),
  // &:global(bg--blue) {
  //   color: var(--black);
  // }
}

.element {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  label {
    //margin: 12px;
    cursor: pointer;

    & + label {
      margin-left: 24px;
    }
  }
  input[type="radio"] {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
  }
  input:checked + .indicator .colorOpt {
    &:after {
      content: "";
      width: 56px;
      height: 56px;
      border-radius: 50%;
      border: 1px solid;
      position: absolute;
      top: -4px;
      left: -4px;
    }
  }
}
