@import "~scss/imports";
.element {
  font-family: "Adieu";
  background: var(--black);
  position: relative;
  color: var(--white);
  z-index: 100;
  padding-bottom: 80px;
  a {
    color: var(--white);
  }&.noPad{
      padding-bottom: 0;
    }

  @include breakpoint(md) {
    padding-bottom: 50px;
    &.noPad{
      padding-bottom: 0;
    }
  }
}
.inner {
  width: 100%;
  padding: var(--padding);
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
  text-align: center;
  @include breakpoint(sm) {
    height: 72px;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.copyright {
  text-transform: uppercase;
  @include breakpoint(sm) {
    position: absolute;
    text-align: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.socialLinks {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  align-items: center;
  justify-content: center;
  li + li {
    margin-left: 1rem;
  }
  @include breakpoint(sm) {
    margin-top: 0;
  }
}
