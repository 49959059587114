@import "~scss/imports";

.element {
  //padding: var(--padding);
  overflow: hidden;
  position: relative;
}

.header {
  max-width: 30em;
  margin: 0 auto;
}

.heading {
  @extend %h2;
}

.steps {
  list-style-type: none;
  padding: 0 var(--padding);
  // display: flex;
  // flex-wrap: nowrap;
  position: relative;
  z-index: 1;
  li:last-child {
    height: 50vh;
  }
}

.step {
  text-align: center;
  display: flex;
  justify-content: center;
  position: relative;
  height: 100vh;
  // transform: translateY(-50%);
}
.body {
  background-color: var(--white);
  padding: 1em;
  border: 1px solid;
  position: absolute;
  bottom: 40%;
  //transform: translateY(100%);
}

.heading {
  @extend %h2;
  text-align: center;
}
.text {
  @extend %p;
  margin-top: 3em;
  max-width: 32rem;
}
.nodes {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.legend {
  font-family: "Good Sans";
  margin-top: 0.75em;
  span {
    background-color: rgb(82, 61, 255);
    width: 30px;
    height: 20px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
  }
  & + .text {
    margin-top: 1em;
  }
}

.mobile {
  padding: var(--padding);
  //max-width: 600px;
  margin: auto;
}

.mobileSteps {
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
  //text-align: center;
}

.mobileStep {
  margin-top: var(--padding);
}

.mobileTitle {
  @extend %h2;
  text-align: center;
}

.mobileHeading {
  @extend %h3;
}
.mobileText {
  @extend %p;
}

.mobileGroups {
  list-style-type: none;
  padding-left: 0;

  @media (min-width: 500px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: var(--padding);
  }
}

.mobileGroup {
  margin-top: calc(var(--padding) / 2);
  position: relative;
  display: flex;
  svg,
  img {
    width: 75px;
    flex-shrink: 0;
  }
  img {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
  svg {
    position: relative;
  }
  > div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 1em;
  }
}

.mobileGroupValue {
  @extend %h2;
  color: var(--blue);
}

.mobileGroupLabel {
  color: var(--blue);
  @extend %h3;
  font-size: 16px;
}
.mobileGroupSublabel {
  @extend %psmall;
  text-transform: uppercase;
  margin-top: 0.5em;
}
