@import "~scss/imports";

.element {
    h1 {
        @extend %h1;
        color: var(--blue);
    }

    h3 {
        @extend %h3;
    }

    hr {
        // content: "";
        width: calc(100% + var(--padding) * 2);
        display: block;
        margin: var(--padding) calc(var(--padding) * -1);
        border: 0;
        border-bottom: 1px solid var(--black);
        // position: absolute;
        bottom: 0;
    }
}

.intro {
    position: relative;
    max-width: 1200px;
    width: 80%;

    h1 {
        color: var(--blue);
    }

    p {
        font-size: 1rem;
        line-height: 1.375;
        font-family: "Good Sans";
    }

    a {
        color: var(--blue) !important;
    }
}

.videoWrap {
    video {
        max-width: 100%;
    }
}