@import "~scss/imports";

.element {
  position: relative;
  //height: 100vh;
  padding: 0 var(--padding) 60vh;
  overflow: hidden;
  z-index: 1;
  padding-bottom: 50vh;
  //padding-top: 50vh;
}
.shapes {
  position: relative;
  height: 100vh;
  position: absolute;
  width: 100%;
  z-index: -1;
  top: 0;
}

.shape {
  position: absolute;

  &:nth-child(1) {
    left: 0;
    bottom: 0;
    width: 75%;
  }
  &:nth-child(2) {
    right: 0;
    bottom: 0;
    width: 80%;
  }
  &:nth-child(3) {
    left: 0;
    top: 0;
    width: 50%;
  }
  &:nth-child(4) {
    top: 0;
    right: 0;
    width: 70%;
  }
  &:nth-child(5) {
    bottom: 0;
    left: 0%;
  }
}

.body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 30vh calc(3 * var(--padding));
  max-width: 1600px;
}

.aside {
  border: 1px solid;
  background-color: var(--white);
  max-width: 550px;
  padding: 1em;
  @extend %p;
  p {
    margin-bottom: 0;
  }
}
.asideLocale {
  @extend %h3;
}
.message {
  @extend %quote;
  margin-top: var(--padding);
  span {
    background-color: var(--green);
  }

  & + .message {
    margin-top: 25vh;
  }
}
