@import "~scss/imports";
// @import "~scss/slick";
// @import "~scss/slick-theme";

.element {
  position: relative;
  width: 100%;
  h2 {
    @extend %h2;
    margin-top: calc(3 * var(--padding));
    color: var(--accent-color);
  }
  .h3 {
    @extend %h3;
  }
  p {
    @extend %p;
    line-height: 1.5;
    //font-size: 1.125rem;
    //line-height: 1.66;
  }
  a {
    color: currentColor;
    text-decoration: none;
    border-bottom: 1px solid currentColor;
  }
}
.heading {
  h2 {
    @include breakpoint(xs) {
      font-size: 22px;
    }
    @include breakpoint(sm) {
      font-size: 32px;
    }
  }
  h3 {
    @include breakpoint(xs) {
      font-size: 16px;
    }
    @include breakpoint(sm) {
      font-size: 24px;
    }
  }
}

.inspiration {
  //margin: 0 calc(-1 / 2 * var(--padding));
  //padding: var(--padding);

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: 1rem;

    // @include breakpoint(sm) {
    flex-direction: row;
    flex-wrap: wrap;
    //margin: var(--padding) calc(-1 * var(--padding));
    // justify-content: space-evenly;
    // }
  }
  li {
    // @include breakpoint(sm) {
    box-sizing: border-box;
    width: calc(50% - 0.5rem);
    //padding: 0 calc(var(--padding) / 2);
    // }
    @include breakpoint(md) {
      width: calc(33.33% - 0.6666rem);
    }
  }
}
.section {
  border-top: 1px solid currentColor;
  margin: 0 calc(-1 * var(--padding));
  padding: var(--padding);
  width: 100%;
  box-sizing: content-box;
  position: relative;
  // box-sizing: content-box;
  &:global(.bg--accent) {
    border-color: inherit !important;
    color: inherit !important;
  }
  :global(.has-media) {
    width: 100%;
    position: relative;
  }
}
.quote {
  width: 2rem;
  height: auto;
  position: absolute;

  &.startquote {
    top: 0;
    left: 0;
  }

  &.endquote {
    bottom: 0;
    right: 0;
    transform: rotate(180deg);
  }
}
.intro {
  position: relative;
  font-size: 1.5rem;
  line-height: 1.4;
  padding: 2rem 0;

  .pullquote {
    font-size: 1.25rem;
    line-height: 1.5;
    font-family: "Good Sans";
    @include breakpoint(sm) {
      font-size: 1.5rem;
      line-height: 1.4;
    }
  }
}

.org {
  border-top: 1px solid currentColor;

  margin: var(--padding) calc(-1 * var(--padding));
  padding: var(--padding) var(--padding) 0;
}
.orgInner {
  display: grid;
  grid-template-areas:
    "summary"
    "contact"
    "social";
  grid-gap: 1rem;

  line-height: 1.75;
  font-family: "Good Sans";
  overflow: hidden;

  @include breakpoint(sm) {
    grid-template-areas:
      "summary summary"
      "contact address"
      "social social";
  }
  @include breakpoint(xl) {
    width: 70%;
  }
}

.summary {
  grid-area: summary;
  span {
    font-weight: 700;
  }
}
.details {
  margin-top: var(--padding);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  display: grid;
}
.contact {
  grid-area: contact;
  @include breakpoint(sm) {
    max-width: 100%;
    word-break: break-all;
  }
  .url {
    // font-weight: 700;
    @include breakpoint(sm) {
      overflow: hidden;
    }
  }
  a {
    border-bottom: 1px solid currentColor;
    &:hover,
    &:focus {
      border-bottom: 2px solid currentColor;
    }
  }
}
.address {
  grid-area: address;
}
.social {
  grid-area: social;
  a {
    &:hover,
    &:focus {
      // color: var(--pink);
      outline: 1px dotted currentColor;
    }
    transition: 0.5s;
    text-decoration: none;
    color: currentColor;
    border-bottom: none;
    font-size: 1.4rem;
    cursor: pointer;
    & + a {
      margin-left: 1rem;
    }
  }
}
.themeIcon {
  position: fixed;
  top: 180px;
  left: calc(2 * var(--padding));
  width: 33%;
  max-width: 350px;
  display: none;
  svg {
    width: 100%;
    height: auto;
  }
  :global(.mobile--only) & {
    width: 120px;
    position: relative;
    top: 0;
    left: auto;
    display: block;
  }
}
.shape {
  display: none;
  @media screen and (min-width: 1024px) {
    display: block;
  }
}
.iconsBadges {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.mobileBadge {
  max-height: 100px;
  svg {
    max-height: 120px;
  }
}
.youtube div {
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 56.25%;
  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
.cost {
  margin-top: 0.5rem;
  @extend %h3;
}

.comments {
  margin: -6rem calc(-1 * var(--padding)) 0;
}

.progressComments {
  margin-top: calc(var(--padding) + 6rem);
  margin-bottom: calc(-1 * var(--padding));
}

.headerStats {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: var(--padding);
}

.anchorNav {
  margin-top: 0;
  list-style-type: none;
  padding-left: 0;
  margin-bottom: calc(2 * var(--padding));
  color: var(--current-color);
  font-size: 18px;

  li {
    border: 1px solid;

    a:first-child:not(:only-child) {
      padding-bottom: 0.5rem;
    }
    a:nth-child(1) {
      font-family: Adieu;
    }
    a:nth-child(2) {
      @extend %p;
      font-size: 14px;
      padding-left: 2rem;
      padding-top: 0.5rem;
    }
  }
  .anchorLink,
  .fakeAnchor {
    padding: 1rem;

    border: 0;
    text-decoration: none;
  }
  .fakeAnchor {
    position: relative;
    opacity: 0.3;
    // &:before {
    //   content: "";
    //   position: absolute;
    //   width: 100%;
    //   height: 100%;
    //   background-color: var(--current-color);
    //   opacity: 0.1;
    //   top: 0;
    //   left: 0;
    // }
  }
  a {
    &:hover,
    &:focus {
      // background-color: var(--current-color);
      // color: var(--bg-color);
      text-decoration: underline;
    }
  }
  li + li {
    border-top: 0;
  }
}

.anchorLink {
  display: flex;
  justify-content: space-between;
  > div {
    visibility: hidden;
    flex-shrink: 0;
    margin-left: 0.5em;
    font-size: 1rem;
  }
}
.currentAnchorLink {
  composes: anchorLink;
  > div {
    visibility: visible;
  }
}

.mobileAnchor {
  @media (min-width: 1024px) {
    display: none;
  }
  //margin: var(--padding) calc(-1 * var(--padding)) calc(-1 * var(--padding));
  // background-color: var(--current-color);
  // color: var(--bg-color);
  // a {
  //   background-color: var(--current-color);
  //   color: var(--bg-color) !important;
  // }
  // > p {
  //   padding-left: var(--padding);
  //   margin-bottom: 0;
  // }
  // ul {
  //   margin-bottom: 0;
  // }
  // li {
  //   border-left: 0;
  //   border-right: 0;
  //   &:last-child {
  //     border-bottom: 0;
  //   }
  // }
}
