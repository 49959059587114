@import "~scss/imports";
$desktop: 1200px;

.element {
    padding: var(--padding) var(--padding) 0;
    border-bottom: 1px solid;
}

.heading {
    @extend %h1;
    margin-top: 0;
}

.body {
    @extend %p;
}

.link,
.button {
    @extend %button;
    margin-top: var(--padding);
    background-color: var(--black);
    color: var(--peach);

    :global(#ForTeens) & {
        color: var(--lilac);

        &:hover,
        &:focus {
            color: var(--black);
        }
    }

    &:hover,
    &:focus {
        background-color: transparent;
        color: var(--black);
        border-color: var(--black);
    }
}

.button {

    &:hover,
    &:focus {
        background-color: var(--lilac);
        color: var(--black);
        border-color: var(--black);
    }
}

.buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include breakpoint(lg) {
        flex-direction: row;
    }

    >div {
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include breakpoint(lg) {

            //flex-direction: row;
            .button {
                margin: 0 1rem 0;
            }
        }
    }

    .button {
        margin: 1rem auto 0;
    }
}

.button {
    @extend %button;
}

.gif {
    position: absolute;
    max-width: 150px;
    width: 100%;
    height: auto;
    right: 0;
    //top: 10px;
    bottom: 100%;

    @media (min-width: 800px) {
        max-width: 200px;
    }

    @media (min-width: 1200px) {
        max-width: 250px;
        top: 150px;
        right: 20px;
    }
}

.step {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 var(--padding) var(--padding);

    // border: 1px solid;
    padding: calc(var(--padding) + 50px) var(--padding) var(--padding);
    // @include breakpoint(sm-max) {
    //   height: auto;
    //   border: 1px solid;
    //   border-bottom: 0;
    //   padding: var(--padding);
    //   & + .step,
    //   & + .scrollStep .step {
    //     border-top: 0;
    //   }
    //   .scrollStep & {
    //     height: 100vh;
    //   }
    // }

    margin-top: 10vh;

    @media (min-width: $desktop) {
        height: 100vh;
        margin-top: 0;
        border: 0;
    }
}

.bottom {
    margin-top: auto;
    position: relative;
}

.pinned {
    position: fixed;
    top: var(--padding);
    height: calc(100vh - 2 * var(--padding));
    width: calc(100% - 2 * var(--padding));
}

.pinnedInner {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}

.currentStep {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.currentStepInner {
    max-width: 666px;
    margin: auto;
}