@import "~scss/imports";

.outer {
    //   width: 100vw;
    //   height: 100vh;
    z-index: 1001;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    top: 0;
    left: 0;
    position: fixed;

    width: 100%;
    height: 100%;
    overflow: auto;
    transition: opacity 0.5s;
    opacity: 0;

    &:global(.enter-done) {
        opacity: 1;
    }

    // display: flex;
    // align-items: center;
    // justify-content: center;
    display: flex;
    flex-direction: column;
}

.element {
    background: var(--black);
    padding: var(--padding);
    width: 100%;
    //height: 100%;
    max-width: 1100px;
    margin: auto;

    // @include breakpoint(md) {
    //   max-width: calc(100vw - var(--padding));
    //   width: auto;
    //   height: auto;
    // }
}

.trigger {
    position: fixed;
    bottom: var(--padding);
    left: var(--padding);
    z-index: 900;
}

.border {
    width: 100%;
    //height: 100%;
    position: relative;
    color: var(--white);

    width: 1100px;

    max-width: 100%;
    //max-height: calc(100vh - var(--padding));
    border: 1px solid var(--white);
    // @include breakpoint(md) {
    //   height: 750px;
    // }
}

.inner {
    width: 100%;
    height: 100%;

    padding: var(--padding);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin: 0 auto;

    h3 {
        @extend %h3;
        text-align: center;
    }

    p {
        @extend %p;

        max-width: 600px;
        line-height: 1.33;
        text-align: center;
    }

    @include breakpoint(md) {
        width: 75%;
    }
}

.closeModal {
    position: absolute;
    right: -1px;
    top: -1px;
    color: var(--white);
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: 1px solid;
    cursor: pointer;
    transition: 0.5s;

    &:hover {
        background-color: var(--peach);
        color: var(--black);
    }

    @include breakpoint(md) {
        transform: translate(0, -50%);
        right: auto;
        top: 50%;
        left: 0;
    }
}

.embedContainer {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
    /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */

    /* Then style the iframe to fit in the container div with full height and width */
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
}