%h1 {
  font-family: Adieu;
  font-style: normal;
  font-weight: bold;
  font-size: var(--size-h1);
  line-height: 1.1;
  margin-bottom: 2rem;
}

%h2 {
  font-family: Adieu;
  font-style: normal;
  font-weight: normal;
  font-size: var(--size-h2);
  line-height: 1.1;
}

%h3 {
  font-family: Adieu;
  font-style: normal;
  font-weight: normal;
  font-size: var(--size-h3);
  line-height: 1.166;
}

%h4 {
  font-family: Adieu;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
}

%p {
  font-family: Good Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

%psmall {
  font-family: Good Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 1.4;
}

%pmed {
  font-family: Good Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
}

%step-number {
  font-family: Adieu;
  font-style: normal;
  font-weight: normal;
  font-size: var(--size-step-number);
  line-height: 1;
}

%quote {
  font-family: Adieu;
  font-style: normal;
  font-weight: bold;
  font-size: var(--size-quote);
  line-height: 1.2;
}

%label {
  font-family: Good Sans;
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  line-height: 1.33;
}

%media-button {
  font-family: Good Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}

%media-filename {
  font-family: Good Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
}

%media-heading {
  font-family: Adieu;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

%stories-form-heading {
  font-family: Adieu;
  font-style: normal;
  font-weight: bold;
  font-size: var(--size-stories-form-heading);
  line-height: 1.1;
}
