@import "~scss/imports";

.element {
    position: fixed;
    width: 100%;
    height: 100%;
    //   padding: calc(var(--padding) + 50px) var(--padding) var(--padding) var(--padding);
    padding: var(--padding);
    display: flex;
    z-index: 1;
    pointer-events: none;
    transition: color 2s;

    color: var(--black);

    &:global(.black),
    &:global(.blue) {
        color: var(--white);
    }
}

.inner {
    border: 1px solid;
    width: calc(100% - var(--padding) * 2);
    // height: calc(100% - var(--padding) * 2 - 50px);
    height: calc(100% - var(--padding) * 2);
    // top: calc(var(--padding) + 50px);
    position: absolute;
    display: flex;

    @media (max-width: 1201px) {
        border: none;
    }
}

.gridFour {
    display: grid;
    grid-template-columns: 33.33% repeat(3, 1fr);
    width: 100%;

    div+div {
        border-left: 1px solid;
    }
}

.menu {
    position: relative;

    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    ul {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        list-style: none;
    }

    a {
        color: var(--white);
        transition: 0.5s;

        &:hover {
            color: var(--yellow);
        }
    }

    @include breakpoint(lg) {
        display: grid;
        grid-template-columns: 33.33% 1fr;
        grid-template-areas: "left right";
        border: 1px solid var(--white);
    }
}

.overlay {
    padding: 0;
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: var(--black);
    overflow: auto;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1001;
    transition: 0.5s;
    color: var(--white);
    // overflow-y: scroll;

    &:global(.enter-done) {
        padding: var(--padding);

        opacity: 1;
    }
}

.navTrigger {
    z-index: 1000;
    position: fixed;
    color: black;
    border: 1px solid;
    top: var(--padding);

    // :global(.home) & {
    //     top: calc(var(--padding) + 50px);
    // }

    width: 30px;
    height: 30px;
    box-sizing: border-box;
    padding: 0;
    right: var(--padding);
    margin: 0;

    cursor: pointer;
    transition: 0.5s;

    &:global(.lilac) {
        background-color: var(--lilac);
    }

    &:global(.peach) {
        background-color: var(--peach);
    }

    &:global(.green) {
        background-color: var(--green);
    }

    &:global(.pale-peach) {
        background-color: var(--pale-peach);
    }

    &:global(.pink) {
        background-color: var(--pink);
    }

    &:global(.white) {
        background-color: var(--white);
    }

    &:global(.yellow) {
        background-color: var(--yellow);
    }

    &:global(.black) {
        color: var(--white);
        background-color: var(--black);

        &:hover {
            color: var(--black);
            background: var(--peach);
        }
    }

    @include breakpoint(md) {
        width: auto;
        padding: 0 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        &:after {
            content: "Menu";
            margin-left: 5px;
            text-transform: uppercase;
            font-family: "Adieu";
        }
    }
}

.navCloseTrigger {
    position: absolute;
    right: calc(var(--padding));
    top: calc(var(--padding));
    box-sizing: content-box;
    padding: 0;
    background: transparent;
    color: var(--white);
    border: 1px solid var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    cursor: pointer;

    svg {
        width: 15px;
        height: auto;
    }

    &:hover {
        transition: 0.5s;
        background-color: var(--peach);
        color: var(--black);
    }

    // @include breakpoint(md) {
    //   left: 0;
    //   right: auto;
    //   top: 50%;
    //   width: var(--padding);
    //   height: var(--padding);
    //   svg {
    //     padding: 5px;
    //   }
    // }
}

.navLogoMobile {
    display: block;

    @include breakpoint(lg) {
        display: none;
    }
}

.navLogo {
    grid-area: left;
    width: 100%;
    justify-content: center;
    margin-top: 20px;
    position: relative;
    z-index: 2;
    display: none;

    svg {
        max-width: 260px;
        height: auto;
    }

    &:before,
    &:after {
        content: "";
        position: absolute;
        border-bottom: 1px solid var(--white);
        width: 100%;
        top: 60px;
        z-index: 1;
    }

    &:after {
        top: 120px;
    }

    @include breakpoint(lg) {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 10px;

        &:before {
            top: 33px;
        }
    }

    @include breakpoint(xl) {
        margin-top: 20px;

        &:before {
            // top: 45px;
            top: 0;
            padding-top: 11%;
        }

        &:after {
            top: 0;
            padding-top: 45%;
        }

        svg {
            width: calc(100% - 4rem);
            max-width: none;
        }
    }
}

.secondaryLinks {
    bottom: 0;
    left: 0;
    grid-area: left;
    margin: var(--padding);

    z-index: 10;

    .secondaryPages {
        margin-bottom: 2em;

        li {
            @extend %h3;
            font-size: 16px;
            line-height: 1.125;

            +li {
                margin-top: 1em;
            }
        }
    }

    @include breakpoint(lg) {
        margin: 39px;

        position: absolute;
    }

    @include breakpoint(xl) {
        .secondaryPages {
            li {
                font-size: 24px;
                line-height: 1.16;
            }
        }
    }
}

.primaryLinks {
    grid-area: right;
    box-sizing: border-box;
    border: 1px solid var(--white);
    margin-top: 1em;

    //height: 50vh;
    //max-height: 450px;
    a {
        text-decoration: none;
        width: 100%;
        height: 100%;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        &:hover {
            background-color: var(--peach);
            color: var(--black);
            border-color: var(--peach);
        }
    }

    ul {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        @extend %h2;
        font-size: 24px;
        line-height: 1.16;
    }

    li {
        flex-basis: 20%;
        width: 100%;
        background-color: var(--black);

        a {
            padding: 1rem;
        }

        &+li a {
            border-top: 1px solid var(--white);
        }

        &:hover+li a {
            border-color: var(--peach);
        }
    }

    @include breakpoint(lg) {
        height: 100%;
        max-height: none;

        a {
            padding: 0 50px;
        }

        margin-top: 0;
        // height: 100%;
        border: none;
        border-left: 1px solid var(--white);
    }

    @include breakpoint(xl) {
        ul {
            font-size: 48px;
            line-height: 1.08;
        }

        // li {
        //   height: 20%;
        // }
    }
}

.socialLinks {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    li+li {
        margin-left: 1.5em;
    }
}