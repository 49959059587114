@import "~scss/imports";

.element {
    background-color: var(--peach);

    .button:hover {
        color: var(--peach);
    }
}

.shape {
    position: fixed;
    left: 0;
    top: 0;
    transform: translateX(-100%);
    pointer-events: none;
}

.howItWorksWrap {
    position: relative;

    .render4 {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 9;
        max-height: 300px;
    }
}