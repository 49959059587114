@import "~scss/imports";

.name {
  margin: 1rem 0;
  font-family: "Adieu";
  @extend %h3;
  margin-bottom: 0.5rem;
  font-size: 1rem;

  height: auto;
  overflow: hidden;

  @include breakpoint(sm) {
    font-size: 1.25rem;
    max-height: 3rem;
  }
  @include breakpoint(lg) {
    font-size: 1rem;
    max-height: 2.25rem;
  }
  @include breakpoint(xl) {
    font-size: 1.25rem;
    max-height: 3rem;
  }
  @media (min-width: 1300px) {
    font-size: 1.35rem;
    max-height: 3.1rem;
  }
}

.meta {
  font-family: "Good Sans";
  font-size: 12px;
  line-height: 1.5;
  @include breakpoint(md) {
    font-size: 0.88rem;
  }
}
.intro {
  padding-bottom: 3rem;
  position: relative;
  h1 {
    color: var(--pink);
  }
  p {
    font-size: 1rem;
    line-height: 1.375;
    font-family: "Good Sans";
  }
  &:after {
    content: "";
    width: calc(100% + 2 * var(--padding));
    display: block;
    margin: 0 calc(-1 * var(--padding));
    border-bottom: 1px solid var(--white);
    position: absolute;
    bottom: 0;
  }
  a {
    color: var(--pink) !important;
  }
}
.element {
  margin: calc(1 * var(--padding)) 0;
  ul {
    width: 100%;
    // display: flex;
    list-style: none;

    @include breakpoint(sm) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
    }
    @include breakpoint(lg) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  a {
    text-decoration: none;
  }
  :global(.stories--updating) {
    opacity: 0.5;
    pointer-events: none;
  }
}
.results {
  @extend %h4;
  margin: var(--padding) 0;
  text-transform: uppercase;
  span {
    color: var(--pink);
  }
}
.filterToggle {
  border-bottom: 1px solid;
  margin: 0 calc(-1 * var(--padding));

  button {
    margin: 1rem 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    background-color: transparent;
    border: 0;
    color: currentColor;
    padding: 0 var(--padding);
    @extend %h3;
  }
  button.closeFilters {
    margin-top: 0;
    width: 100%;
    position: fixed;
    z-index: 100001;
    top: 0;
    right: 0;
    padding: var(--padding);

    border: none;
    width: 100%;
    background-color: var(--black);

    &:after {
      content: "";
      width: 16px;
      height: 3px;
      background-color: var(--white);
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      right: 1rem;
    }
  }
  @include breakpoint(lg) {
    display: none;
  }
}

.badge {
  width: auto;
  height: auto;

  bottom: -50px;
  right: -15px;
  display: inline-block;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: absolute;

  span {
    position: absolute;
    font-family: "Adieu";
    text-align: center;
    transform: translate(-50%, -50%) rotate(-17.92deg);
    left: 50%;
    top: 50%;
  }
  :global(.mobile--only) & {
    position: relative;
    top: 0;
    left: auto;
    right: auto;
    transform: none;
  }
  :global(.tile) & {
    top: -5%;
    left: -5%;
    right: auto;
    transform: scale(0.7) translate(-30%, -30%);

    @media screen and (min-width: 1500px) {
      transform: scale(1);
    }
  }
}

// .themeIcon {
//   position: relative;
//   width: 160px;
//   height: auto;
// }

.badgeInner {
  position: relative;
}
