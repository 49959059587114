@import "~scss/imports";
.element {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  .inner {
    text-align: center;
    background: #000;
    color: white;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Good Sans";
    line-height: 1.5;
    @include breakpoint(md) {
      height: 50px;
    }
  }
  span {
    transition: 0.25s;
    font-weight: 700;
    text-decoration: underline;
  }
}
.link {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;

  &:hover + div {
    span {
      color: var(--yellow);
    }
  }
}
