@import "~scss/imports";

.element {
  stroke: currentColor;
  stroke-width: 3;
  animation: spin 1s linear infinite;
  transform-origin: center;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
