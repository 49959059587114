@import "~scss/imports";

.element {
  form {
    border-top: 1px solid currentColor;
    margin: 0 calc(-1 * var(--padding));
    padding: 0 var(--padding);
    font-family: "Good Sans";
  }
}
