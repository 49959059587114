@import "~scss/imports";

.element {
  position: relative;
  text-align: center;
  background-color: white;
  border: 1px solid black;

  width: calc(100% - 2 * var(--padding));
  margin: 0 var(--padding);
  top: calc(var(--padding) / 2);
  padding: var(--padding);
  box-sizing: border-box;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Good Sans";
  font-size: 16px;
  a {
    color: var(--black);
  }
  .content {
    padding: 0 10px;
  }
  @include breakpoint(md) {
    top: 100px;
  }
  @include breakpoint(lg) {
    // position: fixed;
    top: calc(var(--padding) + 1px);
    height: 50px;

    padding-top: 0;
    padding-bottom: 0;
  }
}
