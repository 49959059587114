@import "~scss/imports";

.element {
  h1 {
    @extend %h1;
    color: var(--blue);
  }
  h3 {
    @extend %h3;
  }

  hr {
    // content: "";
    width: calc(100% + var(--padding) * 2);
    display: block;
    margin: var(--padding) calc(var(--padding) * -1);
    border: 0;
    border-bottom: 1px solid var(--black);
    // position: absolute;
    bottom: 0;
  }
}

.intro {
  position: relative;
  max-width: 1200px;
  width: 80%;
  h1 {
    color: var(--blue);
  }
  p {
    font-size: 1rem;
    line-height: 1.375;
    font-family: "Good Sans";
  }
  a {
    color: var(--blue) !important;
  }
}
.youtubeEmbed {
  width: 100%;
  padding-bottom: 56.25%;
  background-color: var(--blue);
  position: relative;
}
.videoWrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.pic {
  width: 100%;
  img {
    width: 100%;
    height: auto;
    border-radius: 50%;
  }
}
.placeholder {
  background-color: var(--blue);
  width: 100%;
  height: 0;
  padding-bottom: 100%;
}
.tile {
  width: calc(50% - 20px);
  padding-bottom: 10px;
  h3 {
    @extend %h3;
  }

  @include breakpoint(lg) {
    width: calc(33% - 20px);
  }
}
.info {
  height: 200px;
}
.meta {
  font-family: "Good Sans";
  font-size: 14px;
  line-height: 1.55;
  height: 150px;
}
.finresult {
  h4 {
    @extend %h4;
  }
  .amount {
    font-size: 18px;
    font-family: "Adieu";
  }
}
.filterToggle {
  border-bottom: 1px solid;
  margin: calc(-1 * var(--padding)) calc(-1 * var(--padding)) 0;

  button {
    margin: 1rem 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    background-color: transparent;
    border: 0;
    color: currentColor;
    margin: 0;
    padding: var(--padding);
    @extend %h3;
  }
  button.closeFilters {
    margin-top: 0;
    width: 100%;
    position: fixed;
    z-index: 100001;
    top: 0;
    right: 0;
    padding: var(--padding);

    border: none;
    width: 100%;
    background-color: var(--black);

    &:after {
      content: "";
      width: 16px;
      height: 3px;
      background-color: var(--white);
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      right: 1rem;
    }
  }
  @include breakpoint(lg) {
    display: none;
  }
}
.results {
  @extend %h4;
  margin: var(--padding) 0;
  text-transform: uppercase;
  span {
    color: var(--blue);
  }
}
