@import "~scss/imports";

.element {
  border: 0;
  margin: 0;
}

.elementSm {
  border: 0;
  margin: 0;

  display: flex;
  justify-content: flex-start;
  // flex-direction: row;
  flex-direction: column;
  columns: unset;
}

.labelWrap {
  display: inline-block;

  margin: 0.25rem 0;
  position: relative;
  vertical-align: middle;
  padding-left: 30px;
  width: 100%;
  text-align: left;

  // @media (min-width: 1200px) {
  //   min-width: 50%;
  //   width: auto;
  // }
}

.label {
  cursor: pointer;

  &:hover input:not(:checked) ~ span {
    background-color: var(--blue);
  }

  input:checked ~ span {
    background-color: currentColor;
  }
  input:focus-visible ~ .checkbox {
    border-color: var(--blue);
  }
}

.checkbox {
  display: block;
  width: 20px;
  border: 1px solid currentColor;
  left: 0;
  position: absolute;
  top: 3px;
  height: 12px;
}

.input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
