@import "~scss/imports";

.element {
    background-color: var(--yellow);
    color: var(--black);
    padding: var(--padding);
    min-height: 100vh;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.body {
    max-width: var(--max-text);
    margin-left: auto;
    margin-right: auto;
    margin-top: calc(var(--padding) * 2);
    margin-bottom: calc(var(--padding) * 2);
    position: relative;
    z-index: 1;
    padding: 0 var(--padding);
    position: relative;

    .gif6 {
        position: absolute;
        top: -20vh;
        right: -30vh;
        z-index: 9;
    }
}

.heading {
    @extend %h2;
}

.text {
    @extend %p;
}

.link {
    @extend %button;
    margin-top: var(--padding);

    &:hover {
        color: var(--yellow);
    }
}

.shape {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
}