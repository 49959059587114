@import "~scss/imports";

.CityIndexSwitcher-header {
  display: flex;
  justify-content: space-between;
}

.Grid {
  height: 100%;
  width: 100%;
  cursor: pointer;
  canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
