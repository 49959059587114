@import "~scss/imports";

.element {
    width: 100%;
    position: relative;
    border-bottom: 1px solid;
    //z-index: 1;
    //height: calc(100vh - 2 * var(--padding));
    // border-left: 1px solid;
    // border-right: 1px solid;
    // border-bottom: 1px solid;
    // position: sticky;
    // top: var(--padding);
    //height: 400vh;

    @media only screen and (max-width: 800px) {
        padding-top: 14em;
    }
}