@import "~scss/imports";
.element {
  h2 {
    @extend %h2;
  }
  h3 {
    @extend %h3;
  }
  p {
    @extend %p;
    font-size: 18px;
    line-height: 1.66;
  }
  a {
    color: currentColor;
    text-decoration: none;
    border-bottom: 1px solid currentColor;
  }
}
.heading {
  position: relative;

  display: grid;
  grid-template-columns: 100%;
  grid-gap: 1rem;
  align-items: center;
  font-family: "Adieu";
  h3 {
    margin-top: 0;
  }
  margin-bottom: 2rem;
  & > div {
    order: 2;
    &.shape {
      order: 1;
    }
  }
  @include breakpoint(sm) {
    grid-template-columns: 4fr 1fr;
    padding-top: var(--padding);
    padding-right: 0px;
    & > div.shape {
      order: 3;
    }
  }
  @include breakpoint(md) {
    grid-template-columns: 4fr 1fr;
  }
  @include breakpoint(lg) {
    padding-right: 0;
    padding-top: 50px;
  }
  @include breakpoint(xl) {
    padding-top: 0px;
  }
}
.shape {
  // position: absolute;
  right: var(--padding);
  z-index: -1;
  right: 0;
  width: 40%;

  svg {
    width: 80%;
    height: auto;
  }
  @include breakpoint(sm) {
    width: 100%;
    order: 2;
    // max-width: 150px;

    // left: auto;
    // right: 0;
    // top: calc(100px + 2 * var(--padding));
  }
  @include breakpoint(md) {
    //top: calc(180px + 2 * var(--padding));
  }
  @include breakpoint(lg) {
    position: fixed;
    left: 0;
    top: 150px;
    width: calc(33% - var(--padding) * 2.5);
    max-width: calc(465px - var(--padding) * 2);

    right: auto;
    left: calc(2 * var(--padding));
  }
}
.sectHeading {
  margin-bottom: var(--padding);
}
.image,
.staggeredSection,
.wideImageText {
  margin: calc(2 * var(--padding)) 0;
}
.staggeredSection {
  .sectHeading,
  :global(.video),
  :global(.audio),
  :global(.text) {
    width: 75%;
  }
}
.sentence {
  position: relative;
  padding: var(--padding) 0;
  margin: var(--padding) 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  svg {
    path {
      fill: red;
    }
  }
  p {
    margin-left: var(--padding);
    @include breakpoint(md) {
      width: 50%;
    }
  }

  &:after,
  &:before {
    content: "";
    border-top: 1px solid;
    position: absolute;
    width: calc(100% + 2 * var(--padding));
    margin: 0 calc(-1 * var(--padding));
    top: 0;
  }
  &:after {
    top: auto;
    bottom: 0;
  }
}
.imageLeft {
  :global(.image) {
    width: calc(75% - var(--padding));
    position: relative;
    img {
      width: 100%;
      height: auto;
    }
    @include breakpoint(lg) {
      width: calc(50% - var(--padding));
    }
  }
  // .text {
  //   width: 90%;
  //   margin-left: 10%;
  //   @include breakpoint(md) {
  //     width: 75%;
  //     margin-left: 25%;
  //   }
  // }
}

.mediaBlock {
  display: flex;
  flex-direction: column;
  :global(.video),
  :global(.audio) {
    margin-bottom: var(--padding);
  }
  :global(.text) {
    order: 2;
  }
  :global(.has-media) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
.mediaLeft {
  :global(.text) {
    margin-left: 10%;
    @include breakpoint(md) {
      margin-left: 25%;
    }
  }
  :global(.textOnly) :global(.text) {
    margin-left: 0%;
  }
}
.mediaRight {
  .sectHeading,
  :global(.video),
  :global(.audio) {
    order: 1;
    margin-left: 10%;
    @include breakpoint(md) {
      margin-left: 25%;
    }
  }
  :global(.textOnly) :global(.text) {
    margin-left: 10%;
    @include breakpoint(md) {
      margin-left: 25%;
    }
  }
}

.fiftyFifty {
  display: flex;
  flex-direction: column;
  position: relative;
  .text {
    order: 2;
  }
  :global(.image) {
    width: 90%;
  }
  @include breakpoint(lg) {
    :global(.image) {
      width: 100%;
    }
    flex-direction: row;
    .text {
      margin-top: 80px;
      order: 1;
    }
    .image {
      order: 2;
    }
    & > * {
      width: 50%;
      &:nth-child(2) {
        margin-left: var(--padding);
      }
      &:only-child {
        width: 75%;
      }
    }
  }
  :global(.image) {
    margin-top: 0;
  }
}

.wideImageText {
  display: flex;
  flex-direction: column;
  .text,
  .image {
    width: 75%;
  }
  .text {
    margin-left: 10%;
    @include breakpoint(md) {
      margin-left: 25%;
    }
  }
}

.fullWidth {
  .mediaBlock {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  :global(.video),
  :global(.audio) {
    width: 75%;
    margin: 0 auto calc(2 * var(--padding));
    &:global(.multiple) {
      width: calc(50% - var(--padding));
      padding-top: 80px;
      margin: var(--padding) 0;
      & ~ :global(.multiple) {
        padding-top: 0px;
      }
    }
  }

  :global(.text),
  .sectHeading {
    width: 100%;
  }
}


.notFound {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}