@import "~scss/imports";

.element {
  h1 {
    @extend %h1;
  }
  p {
    @extend %p;
    line-height: 1.375;
  }
}
.map {
  position: relative;
  width: 100%;
  margin-top: 4rem;
  min-height: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  svg {
    width: 100%;
    height: auto;
  }
  .links {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  a {
    border: 1px solid var(--black);
    background-color: var(--white);
    border-radius: 50%;
    padding: 2rem;
    font-family: "Adieu";
    text-transform: uppercase;
    font-size: 0.75rem;
    text-decoration: none;
    color: var(--black);
    transition: 0.5s;
    &:hover {
      background-color: var(--black);
      color: var(--white);
    }
    & + a {
      margin-top: 1rem;
    }
  }

  @include breakpoint(md) {
    .links {
      position: absolute;
      width: 100%;
      bottom: 30%;
    }
    a {
      position: absolute;
    }
    .mi {
      left: 10%;
    }
    .ny {
      right: 0;
    }
  }
}
