@import "variables";

@font-face {
    font-family: Adieu;
    src: url("~fonts/GTF Adieu Family WEB/Adieu-Regular.woff2") format("woff2"),
        url("~fonts/GTF Adieu Family WEB/Adieu-Regular.woff") format("woff");
}

@font-face {
    font-family: Adieu;
    src: url("~fonts/GTF Adieu Family WEB/Adieu-Bold.woff2") format("woff2"),
        url("~fonts/GTF Adieu Family WEB/Adieu-Bold.woff") format("woff");
    font-weight: bold;
}

@font-face {
    font-family: Good Sans;
    src: url("~fonts/GTF Good Sans WEB/GoodSans-Regular.woff2") format("woff2"),
        url("~fonts/GTF Good Sans WEB/GoodSans-Regular.woff") format("woff");
}

@font-face {
    font-family: Good Sans;
    font-style: italic;
    src: url("~fonts/GTF Good Sans WEB/GoodSans-RegularItalic.woff2") format("woff2"),
        url("~fonts/GTF Good Sans WEB/GoodSans-RegularItalic.woff") format("woff");
}

@font-face {
    font-family: Good Sans;
    font-style: normal;
    font-weight: 700;
    src: url("~fonts/GTF Good Sans WEB/GoodSans-Bold.woff2") format("woff2"),
        url("~fonts/GTF Good Sans WEB/GoodSans-Bold.woff") format("woff");
}

@font-face {
    font-family: Good Sans;
    font-weight: 700;
    font-style: italic;
    src: url("~fonts/GTF Good Sans WEB/GoodSans-BoldItalic.woff2") format("woff2"),
        url("~fonts/GTF Good Sans WEB/GoodSans-BoldItalic.woff") format("woff");
}

.mobile--only {
    @media screen and (min-width: 1024px) {
        display: none;
    }
}

.desktop--only {
    @media screen and (max-width: 1023px) {
        display: none;
    }
}

/* mobile overflow quickfix */
// #root {
//   width: 100vw;
//   overflow: hidden;
// }

body>svg {
    //Fix for gap left by measurement svg used by react-svg-text
    position: absolute;
    z-index: -1;
    top: 0;
}

/* format -- base_color accent_color accent_color_text story_accent accent_border(optional) */
$colors: (
    "lilac" "black" "white" "blue",
    "peach" "blue" "white" "yellow" "black",
    "pale-peach" "blue" "white" "yellow",
    "pink" "black" "white" "yellow",
    "white" "blue" "white" "yellow",
    "yellow" "purple" "white" "purple",
    "black" "pink" "black" "pink" "" "white",
    "green" "black" "white" "blue",
    "blue" "peach" "black" "yellow",
    "red" "black" "white" "yellow",
    "provider-pink" "black" "white" "black" "" "black",
    "provider-yellow" "purple" "white" "purple" "" "black",
    "provider-black" "pink" "black" "pink" "" "white",
    "provider-black-b" "yellow" "black" "yellow" "" "white",
    "provider-green" "blue" "white" "blue" "" "black",
    "provider-peach" "blue" "white" "blue" "" "black",
    "provider-purple" "peach" "black" "peach" "" "white",
    "provider-lilac" "blue" "white" "blue" "" "black",
    "provider-blue" "peach" "black" "peach" "" "white",
    "provider-white" "pink" "black" "pink" "" "black"
);

@each $color, $accent, $accentText, $storyAccent, $accentBorder,
$textColor in $colors {
    $accentBorder: false !default;

    .bg--#{$color} {
        --current-color: #{$textColor};
        --bg-color: var(--#{$color});
        --accent-color: var(--#{$accent});
        background-color: var(--#{$color});

        .Dropdown-menu {
            background-color: var(--#{$color});
        }

        .swatch-svg>& {
            circle {
                fill: var(--#{$color});
            }
        }

        .bg--flip {
            color: var(--#{$color});
            background-color: var(--black);
        }

        [type="submit"] {

            &:hover,
            &:focus,
            &:active {
                color: var(--#{$color});
                background-color: var(--#{$accent});
            }
        }

        .bg-color--inherit {
            background-color: var(--#{$color});
        }

        .bg--accent {
            background-color: var(--#{$accent});

            color: var(--#{$accentText});

            @if ($accentBorder) {
                border-color: var(--#{$accentBorder});
            }

            @else {
                border-color: var(--#{$accentText});
            }

            .ui {
                // color: var(--#{$color});
            }

            .svg--bg-color {
                path {
                    fill: var(--#{$color});
                }
            }

            button,
            [type="submit"] {

                &:hover,
                &:focus,
                &:active {
                    color: var(--#{$accent});
                    background-color: var(--#{$accentText});
                }
            }

            &:after {
                color: var(--#{$accent});
            }

            // span {
            //   color: var(--#{$accentText});
            // }
        }

        .fg--accent {
            color: var(--#{$accent});
        }

        .story--accent {
            color: var(--#{$storyAccent});
        }

        svg.story--accent {
            path {
                fill: var(--#{$storyAccent});
            }
        }

        .slick-dots li button:before {
            color: var(--#{$accent});
        }
    }

    .fg--#{$color} {
        color: var(--#{$color});
    }
}

.slick-slider {
    margin-bottom: 1rem;
}

.bg--black,
.bg--provider-purple,
.bg--provider-black,
.bg--provider-black-b,
.bg--blue,
.bg--provider-blue {
    .logo {

        rect,
        line {
            stroke: var(--white);
        }

        path {
            fill: var(--white);
        }
    }

    .bg--flip {
        background-color: var(--white);
    }

    color: var(--white);

    a {
        color: var(--white);
    }

    .swatch-svg>& {
        path {
            fill: var(--white);
        }
    }

    .Dropdown-menu {
        border-color: var(--white);
    }

    .Dropdown-option {
        color: var(--white);

        &.is-selected,
        &:hover,
        &:focus,
        &:active {
            color: var(--black);
        }
    }
}

section {
    z-index: 2;
}

.grecaptcha-badge {
    opacity: 0;
    pointer-events: none;
}

.error {
    font-style: italic;
    font-weight: 700;
    font-family: "Good Sans";
    margin-bottom: 1rem;
}

a {
    color: var(--blue);
}

/* slick slider */
.slick-track {
    display: flex;
    justify-content: center;
    align-items: center;

    .slick-slide {
        height: auto;

        >div {
            position: relative;

            img {
                width: 100% !important;
                height: 100% !important;
                object-fit: contain;
                position: absolute;
            }
        }
    }
}

.slick-dots {
    li {
        button {
            &:before {
                font-size: 14px;
                color: black;
            }
        }
    }
}

/* tile override*/
.tile .bg--provider-pink {
    .fg--provider-pink {
        color: var(--black);
    }

    .fg--accent {
        color: var(--white);
    }
}

body {
    overflow-x: hidden;
}