@import "~scss/imports";

.element {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  &:focus {
    border: 1px dotted black;
  }
}

.line {
  border: 0;
  padding: 0;
  margin: 0;
  // border-top: 1px solid;
  // border-bottom: 1px solid;
  // border-right: 1px solid;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: stretch;
  gap: .4rem;
  .label {
    margin: 0;
    min-width: 30px;
    text-align: center;
    border: 1px solid;
    padding-left: 0;
    &:hover > div {
      background-color: var(--current-color);
      color: var(--bg-color);
    }
    > div {
      padding: 0.5rem 0.75rem;
    }
  }
  input:checked ~ div {
    outline: 2px solid;
    font-weight: 700;
  }
}

.label {
  display: inline-block;
  margin: 0.25rem 0;
  position: relative;
  padding-left: 30px;
  font-family: "Good Sans";
  input:focus ~ span,
  input:active ~ span {
    outline: 2px dotted currentColor;
  }
  &:hover {
    cursor: pointer;

    input ~ span {
      background-color: currentColor;
    }
  }

  input:checked ~ span {
    background-color: currentColor;
  }

  //   &:focus {
  //     input:not(:checked) ~ span {
  //       border-color: var(--blue);
  //       background-color: var(--blue);
  //     }
  //   }
}

.radio {
  display: block;
  width: 20px;
  border-radius: 50%;
  border: 1px solid currentColor;
  left: 0;
  position: absolute;
  top: 3px;
  bottom: 3px;
}

.input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
