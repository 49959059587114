@import "~scss/imports";

.element {
  margin-bottom: calc(6rem + var(--padding));
  @extend %p;
  line-height: 1.5;
}

.pullquote {
  position: relative;
  padding: calc(2 * var(--padding)) var(--padding);
  margin: var(--padding) calc(-1 * var(--padding));
}

.quote {
  width: 2rem;
  height: auto;
  position: absolute;

  &.startquote {
    top: var(--padding);
    left: var(--padding);
  }

  &.endquote {
    bottom: var(--padding);
    right: var(--padding);
    transform: rotate(180deg);
  }
}

.quoteAge {
  margin-top: 0;
  margin-left: 1.2rem;
  font-size: 12px !important;
}

.heading {
  @extend %h3;
}
