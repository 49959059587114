@import "~scss/imports";

.heading {
  @extend %h2;
}
.title {
  @extend %h3;
}
.copy {
  @extend %p;
  line-height: 1.375;
}

.form {
  fieldset {
    margin: 0;
    padding: 0;
    border: 0;
    margin: 1rem 0 1.5rem;
  }
  :global(.error) {
    color: var(--red);
  }
  .copy {
    margin-bottom: 2rem;
  }
  input:hover ~ span {
    background-color: var(--black) !important;
  }
  input:checked ~ span {
    background-color: var(--blue) !important;
  }
}
.label {
  @extend %h4;
  display: block;
  margin-bottom: 0.5rem;
}
.text {
  margin-bottom: 1rem;
}
.third {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  & > div {
    width: calc(33.33% - 2rem);
  }
}

.info {
  font-size: 0.8rem;
  line-height: 1.5;
}

.hidden {
  opacity: 0;
  height: 0;
  width: 0;
  margin: 0;
  padding: 0;
}
