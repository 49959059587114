@import "~scss/imports";

.element {
  display: flex;
  flex-wrap: wrap;
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: center;
  //flex-direction: column;
  //position: relative;

  // @media (min-width: 800px) {
  //   flex-direction: row;
  // }
}

.label {
  border: 1px solid transparent;
  display: flex;
  //align-items: center;
  padding: 1rem;
  width: 100%;
  position: relative;

  // @media (min-width: 1000px) {
  //   width: calc(50% - 20px);
  //   width: 50%;
  // }

  // @media (min-width: 1200px) {
  //   width: calc(33.33% - 20px);
  // }

  &:hover {
    border: 1px solid var(--magenta);
    cursor: pointer;
  }

  &:focus,
  &:focus-within {
    //outline: 1px solid var(--magenta);
    border-color: var(--magenta);
  }

  input:checked ~ span {
    background-color: var(--black);
  }
}

.activeLabel {
  composes: label;
  border: 1px solid var(--black);
  &:focus,
  &:focus-within {
    //outline: 1px solid var(--magenta);
    border-color: var(--black);
  }
}

.items {
  padding-left: 0;

  //overrides from layout.module.scss
  padding: 0;
  margin-top: 0.5em;
  margin-bottom: 0.5em;

  font-family: Adieu;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
}
.heading {
  @extend %h3;
}
.text {
  margin: 0.5em 0;
}
.icon {
  width: 50px;
  flex-shrink: 0;
  // path {
  //   fill: unset;
  //   stroke: currentColor;
  //   stroke-width: 2;
  // }
}

.input {
  opacity: 0;
  height: 0;
  width: 0;
  position: absolute;
}

.indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
  margin-right: 1em;
  // padding: 0.25rem;
}

.radio {
  display: none;
  width: 30px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid var(--black);
  //margin: 0 auto;
}
