@import "~scss/imports";

.element {
  audio {
    width: 100%;
    &:focus {
      outline: 0;
      filter: drop-shadow(0 0 1px var(--black));
    }
  }
}
