@import "layout";
@import "type";

%button {
  background-color: inherit;
  border-radius: 50%;
  border: 2px solid;
  color: inherit;
  text-decoration: none;
  padding: var(--button-pad);
  display: inline-block;
  @extend %h3;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;

  &:hover,
  &:focus {
    background-color: var(--black);
    color: var(--lilac);
    transform: scale(1.05);
  }
  &:active {
    transform: scale(0.99);
    background-color: var(--green);
    color: var(--black);
  }
  &:focus {
    outline: 0;
  }
}

%textInput {
  background-color: transparent;
  border-radius: 0;
  border: 1px solid var(--black);
  padding: 8px 10px;
  outline: none;
  width: 100%;

  &:focus[aria-invalid="false"] {
    border: 2px dotted currentColor;
  }

  @extend %p;

  @media (min-width: 1200px) {
    width: auto;
  }
}

%inputLabel {
  position: relative;
}

$break-xs: 350px !default;
$break-sm: 580px !default;
$break-sm-max: 767px !default;
$break-md: 768px !default;
$break-lg: 1024px !default;
$break-xl: 1200px !default;
$break-xxl: 1660px !default;

$breakpoints: (
  "xs": (
    min-width: $break-xs,
  ),
  "sm-max": (
    max-width: $break-sm-max,
  ),
  "sm": (
    min-width: $break-sm,
  ),
  "md": (
    min-width: $break-md,
  ),
  "lg": (
    min-width: $break-lg,
  ),
  "xl": (
    min-width: $break-xl,
  ),
  "xxl": (
    min-width: $break-xxl,
  ),
);

@mixin breakpoint($name) {
  @media #{inspect(map-get($breakpoints, $name))} {
    @content;
  }
}
