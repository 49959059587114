@import "~scss/imports";

$desktop: 1200px;

.element {
  display: flex;
  height: 100%;
}

.inner {
  width: 100%;
  padding-top: var(--padding);
  overflow: hidden;
  //padding-bottom: var(--padding);
  @media (min-width: $desktop) {
    height: calc(100vh - var(--padding));
    //height: 100vh;
    display: flex;

    .header {
      position: relative;
      width: 100%;

      &:after {
        content: "";
        height: calc(100vh - 2 * var(--padding));
        border-right: 1px solid;
        top: 0;
        left: calc(100% - 1px);
        position: absolute;
      }
    }
    .body {
      width: 0%;
      flex-shrink: 0;
      overflow: hidden;
      position: relative;
      border: 0;
    }
    .numbers {
      //display: grid;
      position: relative;
      z-index: 1;
    }
    .step .stepNumber {
      //display: none;
    }
    .stepBody {
      padding: var(--padding);
    }
    .stepBodyDetail {
      text-align: left;
    }
    .progress {
      display: block;
    }
  }
  @include breakpoint(sm-max) {
    margin-top: 0;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--padding);
  position: relative;
  h2 {
    @extend %h2;
    max-width: 500px;
  }
  @include breakpoint(sm-max) {
    border: 1px solid;
    border-bottom: 0;
    padding: var(--padding);
  }
}

.headerShape {
  position: absolute;
  top: var(--padding);
  z-index: -1;
}

.body {
  border: 1px solid;
}

.numbers {
  display: none;
  grid-template-columns: repeat(3, 1fr);
  list-style-type: none;
  border-bottom: 1px solid;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  //border-top: 1px solid;

  :global(.lilac) & {
    background-color: var(--lilac);
  }
  :global(.peach) & {
    background-color: var(--peach);
  }
  li {
    padding: var(--padding);
  }
  li + li {
    border-left: 1px solid;
  }
}

.steps {
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;

  --number-height: 160px;

  display: flex;
  flex-direction: column;
  min-height: 100%;
  align-items: stretch;
  // @include breakpoint(sm-max) {
  //   border: 1px solid;
  //   margin-top: 0;
  // }
  @media (min-width: $desktop) {
    position: absolute;
    width: 100%;
    right: 0;
  }
}

.step {
  //overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid;
  }
  @media (min-width: $desktop) {
    height: calc((100vh - 2 * var(--padding)) / 3);
  }
}
.stepInner {
  //padding: 0 var(--padding);
  display: flex;
  width: 100%;
  height: 100%;
  align-items: stretch;
  //align-items: center;
}
.stepButton {
  display: block;
  background-color: transparent;
  cursor: pointer;
  border: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.activeStep {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // padding-top: 1rem;
  // padding-bottom: 1rem;
  //height: 100%;
}
.inactiveStep {
  flex-shrink: 0;
}
.stepBodyDetail {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.stepNumber {
  @extend %step-number;
  text-align: center;
  border-right: 1px solid;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 150px;
  padding: 1rem;
}
.activeStepNumber {
  composes: stepNumber;
  background-color: var(--yellow);
}

.stepBody {
  max-width: 666px;
  @extend %h3;
  margin-left: auto;
  margin-right: auto;
  //margin-top: 4em;
  padding: var(--padding);

  // @media (min-width: $desktop) {
  //   font-size: 36px;
  // }
}

.link {
  @extend %button;
  margin-top: 4rem;
}

.progress {
  position: absolute;
  left: 13%;
  top: 0;
  z-index: -1;
  display: none;

  svg {
    width: 250px;
  }
}
