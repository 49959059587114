@import "~scss/imports";

.element {
    background-color: var(--peach);
    padding: 0;
}

.collage {
    position: absolute;
    top: -30px;
    right: -100px;

    @media only screen and (max-width: 767px) {
        left: 0;
    }
}

.step {
    padding: 0;
}

.splash {
    color: #1b1a1f;
    min-height: 100vh;
    position: relative;
    padding: 4em 0;
    overflow: hidden;

    // @media only screen and (max-width: 767px) {
    //     &:before {
    //         content: "";
    //         position: absolute;
    //         display: block;
    //         bottom: 100%;
    //         left: 0;
    //         height: 50vh;
    //         width: 100%;
    //         background-color: var(--pink);
    //     }
    // }

    .splashInner {
        flex: 1 1 auto;
        width: 100%;
        margin: 0 auto;
        max-width: 1400px;
        padding: 0 1em;
        display: flex;
        gap: 2em 4em;
        position: relative;
        z-index: 1;

        @media only screen and (max-width: 1100px) {
            flex-direction: column;
        }

        @media only screen and (max-width: 767px) {
            padding: 4em 0;
        }

        .left {
            flex: 1 1 auto;
            width: 60%;
            min-width: 700px;

            @media only screen and (max-width: 1300px) {
                width: 100%;
                min-width: auto;
            }

            .heading {
                font-family: Adieu;
                margin-top: 0;
                font-size: 50px;

                @media only screen and (max-width: 1100px) {
                    max-width: 600px;
                }

                @media only screen and (max-width: 767px) {
                    max-width: none;
                    font-size: 36px;
                }
            }

            .contentWrap {
                max-width: 550px;

                @media only screen and (max-width: 1100px) {
                    color: black;
                    max-width: 500px;
                }

                @media only screen and (max-width: 767px) {
                    max-width: none;
                }
            }

            .buttonWrap {
                margin-top: 4em;
                text-align: center;
                gap: 1em;
                display: flex;
                flex-wrap: wrap;

                .buttonHeadline {
                    margin-bottom: 1em;
                }

                .button {
                    font-size: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;
                    text-decoration: none;
                    padding: 1em 1.5em;
                    flex: 0 0 auto;
                    width: 160px;
                    height: 130px;
                    border-radius: 50%;
                    color: white;
                    background-color: black;
                    border: 2px solid white;
                    transition: 100ms all ease-in;

                    &:hover {
                        background-color: #4C37FE;
                    }
                }
            }

            .linkWrap {
                margin-top: 2em;
                font-size: 24px;
                font-weight: 600;

                @media only screen and (max-width: 767px) {
                    text-align: center;
                }

                a {
                    color: black;
                    text-decoration: none;
                    border-bottom: 3px solid black;
                    padding-bottom: 0.5em;
                    display: inline-block;
                    transition: 100ms all ease-in;

                    &:hover {
                        color: #00fab0;
                    }
                }
            }
        }

        .right {
            flex: 1 1 auto;

            padding-top: 6em;

            @media only screen and (max-width: 1100px) {
                padding-top: 0;
            }

            .imageWrap {
                img {
                    border: 1px solid white;
                    max-width: 100%;
                    cursor: pointer;
                }
            }

            .embedContainer {
                position: relative;
                overflow: hidden;
                width: 100%;
                padding-top: 56.25%;
                border: 1px solid white;

                iframe {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}