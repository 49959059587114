.slider {
  --dim: 3.5rem;
  height: var(--dim);
  display: flex;
  align-items: center;
}

.thumb {
  border: 1px solid;
  border-radius: 100%;
  min-width: var(--dim);
  min-height: var(--dim);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ew-resize;
}

.track {
  background-color: currentColor;
  height: 1px;
}
