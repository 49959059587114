@import "~scss/imports";
.element {
  display: none;

  &.mobileShow {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;

    background-color: var(--black);
    color: var(--white);
    z-index: 10000;
    margin: 0;
    padding: 60px var(--padding) 1rem;
  }
  position: relative;
  width: calc(100% - 2 * var(--padding));
  margin-left: calc(var(--padding));
  margin-top: 100px;
  input[type="checkbox"] {
    width: 30px;
  }
  input[type="text"] {
    width: 100%;
  }
  label {
    font-family: "Good Sans";
    display: flex;
    justify-content: flex-start;
    line-height: 1.25;

    font-size: 0.88rem;
  }
  input[type="checkbox"] {
    font-family: "Good Sans";
    width: 0;
    height: 0;
    opacity: 0;
  }
  p {
    @extend %p;
  }

  @include breakpoint(lg) {
    display: block;
    label {
      padding: 0 0.5rem 0.25rem 0;
    }
  }
  @include breakpoint(xl) {
    margin-left: calc(var(--padding) * 2);
    width: calc(100% - 4 * var(--padding));
  }
}
.checkboxLabel {
  position: relative;
  padding-left: 25px;
}
.element .labelWrap {
  //override breakpoint?
  @media (min-width: 1200px) {
    min-width: 100%;
    width: auto;
  }
  //   @media (min-width: 1600px) {
  //     min-width: 0;
  //     width: 50%;
  //   }
}

.filterSection {
  margin-bottom: 2rem;
}

.clear {
  font-family: Adieu;
  background-color: transparent;
  border: 1px solid;
  color: inherit;
  border-radius: 50%;
  padding: 0.5rem;
  margin: 2rem 0;
  display: block;
  cursor: pointer;
  &:hover,
  &:focus-visible {
    background-color: var(--current-color);
    color: var(--bg-color);
    outline: 0;
  }
}
