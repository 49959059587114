.dropdown {
  width: 100%;
  max-height: 200px;
  overflow-y: scroll;
  position: absolute;
  border: 1px solid var(--black);
  top: 50px;
  z-index: 2000;

  .item {
    width: 100%;
    border: none;
    display: flex;
    justify-content: flex-start;
    background: var(--peach);
    font-size: 1rem;
    padding: 0.5rem;
    display: flex;
    justify-content: flex-start;
    &:hover,
    &:focus {
      background-color: var(--white);
    }
  }
  display: none;
}
.autocompleter {
  position: relative;

  &:focus-within .dropdown {
    display: block;
  }
  input[type="text"] {
    border-color: var(--black);
  }
}
.selectedStory {
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  a {
    text-decoration: none;
  }
  & + .selectedStory {
    border-top: 1px solid currentColor;
  }
}
.removeBtn {
  border: 1px solid var(--black);
  background-color: transparent;
  padding: 0.35rem 0.5rem;
  font-size: 0.7rem;
  line-height: 1;
  transition: 0.5s;
  cursor: pointer;
  display: flex;

  justify-content: center;
  align-items: center;
  &:hover {
    background-color: var(--white);
  }
}
.inspiration {
  margin: 1rem 0 2rem;

  .inspirationInner {
    border: 1px solid var(--black);
  }
  input:read-only {
    opacity: 0;
    height: 0;
    width: 0;
    margin: 0;
    padding: 0;
  }
}
.title {
  font-style: italic;
  margin: 0 0.5rem;
}
.name {
  font-weight: 800;
  margin: 0 0.5rem;
}
