@import "~scss/imports";

.element {
  position: relative;
}
.textarea {
  border: 1px solid currentColor;
  width: 100%;
  height: 110px;
  padding: 1.25em 1.25em 2em;
  @extend %p;
  resize: none;
  display: block;
  &:focus {
    outline: 0;
    border-color: var(--blue);
  }
  &::placeholder {
    opacity: 0.3;
  }
}
.textWrap {
  position: relative;
}
.charCounter {
  color: var(--black);

  position: absolute;
  right: 0.75rem;
  bottom: 0.75rem;
  @extend %psmall;
  padding: 0.25rem;
  background-color: var(--white);
}

.currentCount {
  &:global(.min-not-met) {
    color: var(--red) !important;
  }
}
