@import "~scss/imports";

.container {
  position: relative;
  padding: var(--padding);
  background-color: var(--black);
  color: var(--white);
}

.body {
  max-width: 660px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 var(--padding);
}
.heading {
  @extend %h2;
}
.text {
  @extend %p;
}

.themes {
  position: relative;
  z-index: 1;
  margin-top: var(--padding);
}
.shadowThemes {
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  width: calc(100% - var(--padding) * 2);
}

.themeRow {
  display: flex;
  > * {
    width: 0;
    flex-grow: 1;
  }

  & + .themeRow {
    margin-top: -1px;
  }
}

.themeRowAnimated {
  composes: themeRow;
}
