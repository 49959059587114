.element {
  border: 0;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.buttons {
  list-style-type: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-left: 1px solid;
  border-top: 1px solid;
}
// .buttons + .buttons,
// .buttons + .buttonsAll,
// .buttsonAll + .buttons {
//   margin-left: var(--padding);
// }
.buttonsAll {
  composes: buttons;

  li:first-child {
    grid-column: 1/3;
  }
}
.button {
  display: block;
  background-color: var(--black);
  border-right: 1px solid;
  border-bottom: 1px solid;
  border-top: 0;
  border-left: 0;
  color: inherit;
  width: 100%;
  font-size: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: 14px;
  height: 100%;
  padding: 0.25em;

  @media (min-width: 800px) {
    padding: 0.4rem 0.6rem;
  }

  &:focus {
    outline: 0;
  }
}
.active {
  composes: button;

  background-color: var(--white);
  border-color: var(--white);
  color: var(--black);
}
