@import "~scss/imports";
.element {
  position: relative;
  width: calc(100%);
  margin-bottom: calc(2 * var(--padding));
  a {
    color: currentColor;
    text-decoration: none;
  }
  @include breakpoint(sm) {
    width: calc(50% - 20px);
    margin-bottom: calc(1.5 * var(--padding));
  }
  @include breakpoint(lg) {
    width: calc(33% - 20px);
  }
}
.imageWrap {
  // width: 100%;;
  // height: auto;
  // height: 0;
  // padding-bottom: 66.66%;
  // border: 1px solid currentColor;
  // overflow: hidden;
  position: relative;
  svg,
  img {
    // position: absolute;
    width: 100%;
    // height: 100%;
    height: auto;
    position: relative;
  }
}

.show {
  display: block;
}
.info {
  height: auto;
  overflow: hidden;
  word-break: break-word;
  hyphens: auto;
  @include breakpoint(sm) {
    min-height: 130px;
  }
}
