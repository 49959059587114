@import "~scss/imports";

.element {
  display: grid;
  grid-gap: var(--padding);
  align-items: center;
  background-color: var(--white);
  border: 1px solid;
  min-height: 100px;
  padding: var(--padding);
  audio {
    max-width: 300px;
    width: 100%;
  }
  @media (min-width: 375px) {
    grid-template-columns: 100px 1fr;
  }

  &.audio {
    grid-template-columns: 1fr;
    @media (min-width: 600px) {
      grid-template-columns: 300px 1fr;
    }
  }
}

.filename {
  opacity: 0.45;
  @extend %media-filename;
  color: var(--black);
  word-break: break-all;
}
