@import "~scss/imports";

.element {
  background-color: var(--green);
  .content {
    h1,
    a {
      color: var(--magenta);
    }
    .sectionTitle {
      @extend %h3;
    }
    p {
      font-family: "Good Sans";
      font-size: 1rem;
      line-height: 1.375;
    }
  }
}
