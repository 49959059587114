@import "~scss/imports";

.element {
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-top: 50vh;
  svg {
    position: relative;
    width: 180%;
    left: 50%;
    transform: translate(-50%);

    @media (min-width: 800px) {
      width: 100%;
      left: 0;
      transform: none;
    }
  }
}
.text {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  color: var(--black);
}
.number {
  @extend %h2;
}
.connector {
  @extend %h3;
  text-transform: uppercase;
  margin: 0.5em 0;
}
.descriptor {
  @extend %h3;
}
