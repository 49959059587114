@import "~scss/imports";

.error {
  @extend %p;
  color: var(--red);
}

input[aria-invalid="true"] {
  border-color: var(--red);
  color: var(--red);
}

.Tooltip {
  background-color: var(--blue) !important;
  background-size: contain;
  background-position: 0 0;
  max-width: 450px;
  opacity: 1 !important;
  @extend %p;

  &:before,
  &:after {
    display: none;
  }

  &.green {
    background-color: var(--green) !important;
    color: var(--black) !important;
  }

  img {
    display: none;
  }
  @media (max-width: 767px) {
    left: 0 !important;
    padding: var(--padding) calc(2 * var(--padding)) !important;
  }
  @media (min-width: 1200px) {
    background-color: transparent !important;

    img {
      display: block;
      transform: scale(1.3);
    }

    p {
      position: absolute;
      width: 75%;
      padding: var(--pad);
      padding-right: 1.33em;
      left: 50%;
      top: 50%;
      transform: translate(-25%, -52%);
      font-size: 14px;
    }

    &.green {
      background-color: transparent !important;
      color: var(--black) !important;
      max-width: 350px;

      p {
        transform: translate(-36%, -85%);
        width: 80%;
      }
    }
  }
}
