@import "~scss/imports";
.element {
  a {
    color: var(--black);
  }
  h4 {
    @extend %h4;
    font-size: 1rem;
    line-height: 1.25;
    background-color: var(--black);
    color: var(--pink);
    margin: 0 calc(-1 * var(--padding));
    padding: var(--padding);
    @include breakpoint(lg) {
      font-size: 1.25rem;
    }
    // text-align: center;
  }
  p {
    @extend %p;
  }
  h3 {
    @extend %h3;
  }
}
