@import "~scss/imports";

.element {
  padding: var(--padding);
  h3 {
    margin-top: 0;
  }
  h5 {
    font-family: "Adieu";
    font-size: 1.2rem;
    text-transform: none;
    margin-top: 0;
  }
  hr {
    margin: 0 calc(-1 * var(--padding)) var(--padding);
    border-top: 1px solid;
  }
}
.screenname {
  font-weight: 700;
}
.comment {
  @extend %p;
  margin: 0rem 0 1rem;
  padding: 1rem 0;
}
.from {
  font-style: italic;
  font-weight: 400;
}
.reply {
  // color: var(--blue);
  font-family: "Good Sans";
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.8rem;
  margin: 1rem 0;
  cursor: pointer;
}
.parent {
  border-top: 1px solid currentColor;
  margin: 0 calc(-1 * var(--padding));
  padding: 1rem var(--padding) 0;
}
.child {
  margin-left: 0.5rem;
  padding-left: 2rem;

  border-left: 1px solid currentColor;
}

.types {
  display: flex;
  font-family: "Good Sans";
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.8rem;
  margin-bottom: 2rem;
  .type {
    border: none;
    background-color: transparent;
    font-family: inherit;
    text-transform: uppercase;
    color: inherit;
    font-weight: 700;
    padding: 0;
    &:hover,
    &:focus {
      background-color: transparent !important;
      color: currentColor !important;
      border-bottom: 2px solid currentColor;
    }
    // &:focus {
    //   background-color: transparent !important;
    // }
    margin: 0 0.5rem;

    cursor: pointer;
    &.active {
      border-bottom: 2px solid currentColor;
    }
  }
}

.form {
  input,
  textarea {
    width: 100%;
    margin-bottom: 1rem;
    box-sizing: border-box;
  }
  textarea {
    border: 1px solid currentColor;
    height: 100px;
  }
  fieldset {
    border: none;
    padding: 0;
    margin-bottom: 2rem;
  }

  button {
    width: auto;
  }
}

.loading {
  font-size: 0.8rem;
  font-weight: 500;
  text-transform: uppercase;
  font-family: "Good Sans";
  margin: 0 0 2rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  color: currentColor;
  span {
    margin-left: 0.5rem;
  }
}
.commenting {
  border-color: currentColor;
}

.commentsCount {
  font-size: 16px;
}

.toggle {
  font-family: Adieu;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid;
  padding: 1rem;
  display: block;
  color: inherit;

  margin: 2rem auto;

  &:hover,
  &:focus {
    background-color: var(--current-color);
    color: var(--bg-color);
  }
}
