@import "~scss/imports";

.trigger {
    z-index: 100;
    position: fixed;
    left: var(--padding);
    bottom: var(--padding);
    border: 1px solid;
    font-family: "Adieu";
    text-transform: uppercase;
    padding: 1em;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0;
    color: black;
    font-size: 11px;
    text-decoration: none;
    transition: 150ms all ease-in;

    &:global(.lilac) {
        background-color: var(--lilac);
    }

    &:global(.peach) {
        background-color: var(--peach);
    }

    &:global(.pale-peach) {
        background-color: var(--pale-peach);
    }

    &:global(.pink) {
        background-color: var(--pink);
    }

    &:global(.white) {
        background-color: var(--white);
    }

    &:global(.yellow) {
        background-color: var(--yellow);
    }

    &:global(.black) {
        background-color: var(--black);
        color: var(--white);
    }

    display: none;

    @media (min-width: 800px) {
        display: flex;
    }
}

.triggerText {
    border-bottom: 1px solid;
}

.mainWrap {
    @media only screen and (max-width: 767px) {
        &:before {
            content: "";
            position: absolute;
            display: block;
            bottom: 100%;
            left: 0;
            height: 50vh;
            width: 100%;
            background-color: var(--peach);
        }
    }
}