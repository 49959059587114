@import "~scss/imports";

.logo {
    position: fixed;
    top: var(--padding);

    //   :global(.home) & {
    //     top: calc(var(--padding) + 50px);
    //   }
    margin-left: var(--padding);
    pointer-events: all;
    align-self: flex-start;
    z-index: 100;
    transition: color 1s;
    color: var(--black);

    a,
    a:visited {
        color: var(--black);
    }

    &:global(.blue) a,
    &:global(.blue) a:visited,
    &:global(.black) a,
    &:global(.black) a:visited {
        color: var(--white);
    }

    &:global(.black) {
        color: var(--white);
    }

    @include breakpoint(sm-max) {
        // margin-left: calc(var(--padding) / 2);
        position: relative;
        margin: var(--padding);
        margin-top: 0;
    }
}

.logoLink {
    z-index: 3;
    position: relative;
}

.logoMobile {
    display: none;

    @include breakpoint(sm-max) {
        display: block;
        position: fixed;
        top: var(--padding);
        left: var(--padding);

        // :global(.home) & {
        //     top: calc(var(--padding) + 50px);
        // }
    }
}

.logoDesktop {
    display: block;

    svg {
        background-color: transparent !important;

        &:global(.bg--black),
        &:global(.bg--blue) {
            path {
                fill: var(--white);
            }

            rect,
            line {
                fill: transparent;
                stroke: var(--white);
            }
        }
    }

    @include breakpoint(sm-max) {
        padding-top: 50px;
        top: 0;
        left: 0;
        box-sizing: border-box;
        z-index: 1;
        position: relative;

        svg {
            width: 100%;
            height: auto;
        }
    }
}

.z {
    background: transparent;
    position: absolute;
    top: -10px;
    left: 20%;
    z-index: 2;
    display: none;

    @include breakpoint(md) {
        display: block;
    }
}

.backing {
    width: 60px;
    height: 30px;
    position: fixed;
    top: calv(var(--padding) + 50px);
    left: var(--padding);
    z-index: 2;
    transition: background-color 0.5s;

    @include breakpoint(md) {
        width: 180px;
        height: 59px;
        z-index: 1;
    }
}