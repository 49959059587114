@import "~scss/imports";
$desktop: 1200px;

.element {
    background-color: var(--lilac);
    // padding-top: 30vh;
    position: relative;
    overflow-x: hidden;

    // &:before {
    //     content: "";
    //     position: absolute;
    //     display: block;
    //     bottom: 100%;
    //     left: 0;
    //     height: 50vh;
    //     width: 100%;
    //     background-color: var(--lilac);
    // }

    @media (min-width: $desktop) {
        padding-top: 0;
    }
}

.collage {
    position: absolute;
    right: var(--padding);
    top: calc(100px + 25vw);
    pointer-events: none;

    img {
        height: 100vh;
        width: auto;
        // position: absolute;
        // right: 0;
        // top: 0;
    }

    @media (min-width: 768px) {
        top: 0;
    }
}

.z {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    transform: rotate(33deg);
}

.gif {
    position: absolute;
    max-width: 150px;
    width: 100%;
    height: auto;
    right: 0;
    //top: 10px;
    bottom: 100%;

    @media (min-width: 800px) {
        max-width: 200px;
    }

    @media (min-width: 1200px) {
        max-width: 250px;
        top: 150px;
        right: 20px;
        bottom: auto;
    }
}

.buttonHeadline,
.providerSearch {
    text-align: center;
    font-family: "Adieu";
    font-size: 18px;
    font-weight: 700;
    margin: 1.5em 0 1em;

    a:hover {
        text-decoration: none;
    }
}

.howItWorkedWrap {
    position: relative;

    .render1 {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 9;
    }
}