@import "~scss/imports";
.element {
  //margin-top: var(--padding);
  // padding-bottom: var(--padding);
}
.buttons {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;

  display: grid;
  grid-template-columns: repeat(auto-fit, 120px);
  grid-gap: 1em;
}
.buttonsItem {
  & + .buttonsItem {
    //margin-left: 1em;
  }
}

.buttonLimit {
  padding: 0 1em;
  @extend %h4;
  color: var(--blue);
}

.button,
:global(.filestack-react) {
  background-color: transparent;
  border-radius: 0;
  color: currentColor;
  border: 1px solid;
  color: inherit;
  @extend %media-button;
  padding: 0.4em 0.75em;
  cursor: pointer;
  width: 100%;
  min-width: 120px;
  transition: color 0.3s, background-color 0.3s;

  &:hover,
  &:focus {
    background-color: var(--black);
    color: var(--peach);
    outline: 0;
  }
  &:after {
    content: "+";
    padding-left: 0.5em;
  }
}
.items {
  padding-left: 0;
}
.item {
  position: relative;
  & + .item {
    margin-top: var(--padding);
  }
}
.itemRemove {
  border-radius: 0;
  background-color: var(--white);
  border: 1px solid;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
  padding: 0.25rem;

  svg {
    display: block;
  }
}

.instructions {
  @extend %h4;
  color: var(--blue);
}

.heading {
  @extend %media-heading;
  margin-bottom: 0.25em;
  margin-top: 1em;
  text-transform: uppercase;
}

.dragHandle {
  width: 26px;
  height: 26px;
  padding: 6px;
  border: 1px solid var(--black);
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-50%, -50%);

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);

  svg * {
    fill: var(--black);
  }
}
.hidden {
  height: 0;
  width: 0;
  border: 0;
  padding: 0;
  margin: 0;
}

:global(.slick-slide) {
  div {
    height: 0;
    padding-bottom: 75%;
    object-fit: contain;
  }
}
