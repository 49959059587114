@import "~scss/imports";

.element {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50vh;
    overflow: hidden;
}

.heading {
    @extend %h1;
    height: calc(100vh - var(--padding));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    z-index: 1;
    width: 100% !important;
}

.imageWrap {
    @media only screen and (max-width: 800px) {
        margin-left: auto !important;
        margin-right: auto !important;
        max-width: 100%;
    }

    .image {
        //   width: 50%;
        padding: 2em;
        display: block;
        margin-top: 10vh;

        @media only screen and (max-width: 800px) {
            padding: 0;
            max-width: 100%;
        }
    }

}

// .odd {
//     margin-left: auto;
// }