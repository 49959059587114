@import "~scss/imports";
.element {
  background-color: var(--pale-peach);
  font-family: "Good Sans";
  color: var(--black);
  h1 {
    color: var(--blue);
  }
  h2 {
    font-family: "Adieu";
    font-size: 24px;
    line-height: 1.166;
    margin-top: 48px;
  }
  h3 {
    @extend %h3;
  }
  .applicationEnd {
    text-align: center;
    margin: 2em auto;
  }
  p,
  ul {
    font-size: 1rem;
    line-height: 1.375;
  }
  ul,
  ol {
    padding: 0 1rem 0 2rem;
    margin: 0;
    margin-top: 26px;
    line-height: 1.5;
    ul,
    ol {
      margin: 0;
    }
    li {
      margin: 0.5rem 0;
    }
  }

  p button {
    border: none;
    margin: 0;
    padding: 0;
    font-family: "Good Sans";
    background: none;
    font-size: 1rem;
    text-decoration: underline;
    cursor: pointer;
  }
}
