@import "~scss/imports";
.element {
  padding-bottom: calc(1.5 * var(--padding));
}
.heading {
  @extend %stories-form-heading;
}
.body {
  @extend %p;

  h3 {
    @extend %h3;
    margin-top: calc(3 * var(--padding));
  }

  strong a {
    text-decoration: none;
  }
}
.railImage {
  width: 100%;
  max-width: 580px;
  position: relative;
  pointer-events: none;
  @include breakpoint(lg) {
    position: absolute;
    width: 38%;
    left: -1%;
  }
}
.collage {
  width: 100%;
}
.eye {
  width: 25%;
  position: absolute;
  bottom: 20%;
  right: 7%;
}
