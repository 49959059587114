@import "~scss/imports";

%vertical {
  @media (min-width: 768px) {
    writing-mode: vertical-rl;
    text-orientation: mixed;
    transform: rotate(-180deg);
  }
}

%fadein {
  animation: fadeIn 1s forwards 300ms;
  opacity: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

%fadeslide {
  animation: fadeSlide 1s forwards 800ms;
  opacity: 0;
}

@keyframes fadeSlide {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

.theme {
  padding: 1em 2em;
  border: 1px solid;
  flex-grow: 1;
  text-align: center;
  overflow: hidden;
  position: relative;
  background-color: transparent;
  cursor: pointer;
  min-height: 350px;
  color: inherit;
  margin: 0;
  transition: background-color 0.3s, color 0.3s;

  &:hover:not(.active) {
    background-color: var(--white);
    color: var(--black);
  }

  & + .theme {
    border-left: 0;
  }
  &:focus,
  &:active {
    outline: none;
  }
}

.icon {
  // width: 5em;
  // //height: 5em;
  //background-color: gray;
  margin: 0 auto;
}

.collapsed {
  width: auto;
  flex-grow: 0;
  display: flex;
  .icon {
    display: none;
  }
}

.active,
.collapsed {
  .header {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
  }
  .heading {
    @extend %vertical;
    order: 1;
    margin-top: auto;
    margin-bottom: auto;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .icon {
    order: 2;
    margin-top: auto;
  }

  @media (min-width: 768px) {
    .header {
      min-height: 20em;
      flex-direction: column;
    }

    .heading {
      max-height: 10em;
      justify-content: flex-start;
    }
  }
}

.active {
  background-color: white;
  color: black;

  .heading {
    @extend %vertical;
  }
  .icon {
    margin-top: 1em;
  }

  @media (min-width: 768px) {
    display: flex;
  }
}

.inner {
  @extend %p;
  overflow: hidden;
  @extend %fadein;
  //min-height: 8em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  //position: absolute;
}

.heading {
  @extend %h3;
  margin-top: 1em;
}

.description {
  @extend %p;
  /* or 125% */

  margin-top: 1em;
}

.itemsWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 2rem 0;

  & > .itemHeading {
    text-align: left;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--black);
    @extend %fadeslide;
  }

  @media (min-width: 768px) {
    padding: 0;

    & > .itemHeading {
      margin: 0 5vw;
      position: absolute;
      top: calc(5vw - 2rem);
      width: calc(100% - 10vw);
    }
  }
}
.items {
  // padding-top: calc(5vw + 2em);
  text-align: left;

  @extend %fadeslide;

  @media (min-width: 768px) {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 5vw;
    position: absolute;
    height: 100%;
    width: 100%;
  }
}

.item {
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
  margin-top: 1em;

  @media (min-width: 768px) {
    width: 33.33%;
  }
}

.itemHeading {
  margin-right: 1em;
  margin-bottom: 0.5rem;
  @extend %h4;
  // font-
  // font-family: Adieu;
  // text-transform: uppercase;
}
