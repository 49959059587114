@import "~scss/imports";

.element {
  width: 100%;
  a {
    color: inherit;
  }
}

.error {
  color: var(--red);
}

.question {
  width: 100%;
  margin: 1rem 0;

  :global(.Dropdown-control) {
    max-width: 100%;
    font-weight: 700;
    font-size: 16px;
    font-family: "Good Sans";
    text-transform: none;
  }
}

.subsection {
  padding-top: var(--padding);
  p {
    @extend %p;
    margin-top: 0;
  }
}

.submit {
  @extend %button;
  width: 100%;
  margin-top: var(--padding);
}
.permission {
  visibility: hidden;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
}
.hidden {
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
}
