@import "~scss/imports";

$desktop: 900px;

.element {
  padding: calc(var(--padding) * 2) var(--padding);
  background-color: var(--black);
  color: var(--white);
}

.elementDesktop {
  composes: element;
  background-color: var(--black);

  display: grid;
  align-items: center;
  grid-template-columns: 300px 1fr;
  :global(.CityIndexSwitcher) {
    width: 100%;
  }
  .tiles {
    height: 70vh;
    padding-left: var(--padding);
  }
  .header {
    margin: 0;
    padding-left: var(--padding);
    //padding-right: var(--padding);
  }

  .key {
    max-width: 312px;
    margin-bottom: 1em;
    //margin-right: 1em;
    margin-left: 1em;
  }

  .sidebar {
    //padding: calc(var(--padding) * 0.5);
  }
}

.elementMobile {
  composes: element;

  .sidebar {
    margin-top: 1em;
  }
}

.mobileGrid {
  display: flex;
}

.list {
  list-style-type: none;
  padding-left: 0;
  width: 50%;
  margin-top: 0;
  margin-bottom: 0;
  border: 1px solid var(--white);
  @extend %pmed;
  li {
    & + li {
      border-top: 1px solid var(--white);
    }
    > button {
      display: block;
      border: 0;
      padding: 0.25em;
      width: 100%;
      cursor: pointer;
      background-color: transparent;
      margin: 0;
    }
  }
}
.listOver {
  composes: list;
  li {
    background-color: var(--red);
    color: var(--black);
  }
}
.listUnder {
  composes: list;
  li {
    background-color: var(--lilac);
    color: var(--black);
  }
}

.switchContainer {
  width: 100%;
  // height: 70vh;

  position: relative;
  // > div {
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  // }
  :global(.CityIndexSwitcher) {
    width: 100%;
    height: 100%;
  }
  // .main {
  //   position: static;
  // }
  .main {
    width: 100%;
    height: 100%;
  }
  .tiles {
    height: 100%;
    width: 100%;
  }
  .key {
    margin-bottom: 1em;
    margin-top: 0;
    position: sticky;
    top: 0;
    background-color: var(--black);
    ul {
      border: 1px solid var(--white);
    }
  }
}

.header {
  // margin-left: auto;
  // margin-right: auto;
  // display: flex;
  // padding: 0 1em;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.headerContent {
}

.controls {
  display: flex;
  //border-top: 1px solid;
}

.headerContent {
  max-width: 31em;
  margin: 0 auto var(--padding);
  @media (min-width: $desktop) {
    h2 {
      margin: 0;
      flex-shrink: 0;
    }
  }
}
.filter {
  // padding-left: var(--padding);
  // padding-right: var(--padding);

  :global {
    .Dropdown-root {
      top: 0;
    }
    .Dropdown-control {
      color: var(--white);
      border-color: var(--white);
      border-bottom-width: 2px;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      padding-left: 0;
      min-width: 150px;
    }
    .Dropdown-arrow {
      border-color: var(--white) transparent transparent;
    }
    .Dropdown-menu {
      background-color: var(--black);
      border-color: var(--white);
    }
    .Dropdown-option {
      color: var(--white);
      &:hover,
      &.is-selected {
        color: var(--black);
      }
    }
  }
}

.buttons {
  list-style-type: none;
  padding-left: 0;
}

.heading {
  @extend %h2;
}
.body {
  @extend %p;
}

.filter {
  // display: flex;
  // flex-wrap: wrap;
  display: grid;
  grid-gap: 10px;

  //align-items: flex-end;
  @media (min-width: 375px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 500px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (min-width: 800px) {
    grid-template-columns: auto auto 1fr;
    justify-items: start;
    padding-left: var(--padding);
  }

  label {
    @extend %label;
  }

  // > * + * {
  //   margin-left: 1em;
  // }
}

.fieldset {
  border: 0;
  margin: 0;
  padding: 0;
  width: 100%;

  // border-left: 1px solid;
  // border-top: 1px solid;
  // border-right: 1px solid;
  padding-bottom: 0.6rem;

  // @media (min-width: $desktop) {
  //   margin-left: 400px;
  // }
}

.key {
  @extend %label;
  width: 100%;

  ul {
    list-style-type: none;
    display: flex;
    padding-left: unset;
    margin-top: 0.5em;
    margin-bottom: unset;
    align-items: stretch;
  }
  li {
    padding: 0.5em;
    width: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.main {
  position: relative;
}
.tiles {
  height: 55vh;

  :global(.CityIndex) {
    height: 100%;
    width: 100%;
  }
}

.legend {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 1em;
}

.sidebar {
  text-align: center;
  overflow: auto;
  width: 100%;
  z-index: 1;
  background-color: var(--black);
}

.sidebarClose {
  border: 2px solid;
  color: inherit;
  background-color: transparent;
  margin-bottom: 1em;
  cursor: pointer;
}

.sidebarHeading {
  font-family: Good Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 0.5em;
}
.sidebarText {
  @extend %label;
  min-height: 3em;
}

.sidebarItems {
  list-style-type: none;
  padding-left: 0;
  margin-top: 1em;
  margin-bottom: 0;
}
.sidebarItem {
  text-align: center;
  & + .sidebarItem {
    margin-top: 1em;
  }
}
.sidebarItemLabel {
  @extend %psmall;
  text-transform: uppercase;
}

.sidebarItemList {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
}
.sidebarItemListItem {
  width: 50%;
  padding: 0.5em;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--black);
  border: 1px solid;
  @extend %psmall;
}
.sidebarItemListItemOver {
  composes: sidebarItemListItem;
  background-color: var(--red);
}
.sidebarItemListItemUnder {
  composes: sidebarItemListItem;
  background-color: var(--lilac);
}
