@import "~scss/imports";

.full {
  margin-top: 1rem;
  margin: 1rem 0;
  @media (min-width: 1200px) {
    input {
      width: calc(100% - 20px);
    }
  }
}
.half {
  margin-top: 1rem;
  > div,
  > fieldset {
    margin-bottom: 1rem;
  }

  @media (min-width: 1200px) {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    > div,
    > fieldset {
      display: inline-block;
      width: calc(50% - 1px);

      input {
        width: calc(100% - 20px);
      }
    }
  }

  &:global(.pad) {
    padding: 20px 0;
  }
}
.thirds {
  @media (min-width: 1200px) {
    > div,
    > fieldset {
      display: inline-block;
      width: 33.33%;

      input {
        width: calc(100% - 20px);
      }
    }
  }
}
.submit {
  color: inherit;
  border: 1px solid currentColor;
  border-radius: 50%;
  background-color: transparent;
  display: block;
  margin: 10px;
  padding: var(--padding);
  width: calc(100% - 20px);
  outline: none;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;

  @extend %h2;

  &:hover,
  &:focus,
  &:active {
    &:not([disabled]) {
      cursor: pointer;

      background-color: currentColor;
      border-color: currentColor;
    }
  }

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }

  @media (min-width: 1200px) {
    margin: var(--padding);
    width: calc(100% - var(--padding) * 2);
  }
}
.hidden {
  height: 0;
  width: 0;
  opacity: 0;
  margin: 0;
  padding: 0;
}
