@import "~scss/imports";

.element {
  position: relative;
  border-bottom: 1px solid currentColor;
  padding: var(--padding);
  margin: 0 calc(-1 * var(--padding));
  display: grid;
  grid-gap: 2em;
  &:last-child {
    border-bottom: none;
  }

  > p:first-child {
    margin-top: 0;
  }
  h5 {
    margin: 0;
  }
}
.heading {
  @extend %h3;
  margin-top: 0;
}
.instructions {
  @extend %p;
  margin: 1rem 0;
}
