.element {
  //margin: var(--padding) 0;
  header {
    margin-left: calc(-1 * var(--padding));
    margin-right: calc(-1 * var(--padding));

    background-color: var(--story-accent);
    border-top: 1px solid;
    border-bottom: 1px solid currentColor;

    padding: 8px var(--padding);
    font-size: 14px;
    line-height: 1;

    font-family: "Adieu";

    text-transform: uppercase;
  }
  .inner {
    //color: var(--white);
    //padding-top: var(--padding);
  }
}
