@import "~scss/imports";

$desktop: 1200px;

.element {
    background-color: var(--black);
    color: var(--white);
    position: relative;
    padding: var(--padding);
    min-height: 100vh;
    display: flex;
    overflow: hidden;

    @media only screen and (max-width: 800px) {
        display: block;
    }
}

.inner {
    position: relative;
    width: 100%;
}

.headerSteps {
    @media (min-width: $desktop) {
        display: grid;
        grid-template-columns: 1fr 2fr;
        padding: var(--padding);
    }
}

.header {
    @media (min-width: $desktop) {
        padding: var(--padding);
    }

    display: flex;

    h2 {
        @extend %h2;
        margin-top: 0;
        max-width: 31em;
    }

    .text {
        @extend %p;
        max-width: 31em;
    }
}

.headerContent {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: calc(100vh);
    margin-top: calc(-2 * var(--padding));
    padding-top: var(--padding);
    padding-bottom: var(--padding);
    padding-right: var(--padding);
    position: relative;
    border-right: 1px solid transparent;

    // &:after {
    //   content: "";
    //   display: block;
    //   //border-right: 1px solid;
    //   position: absolute;
    //   top: var(--padding);
    //   right: 0;
    //   height: calc(100vh - 2 * var(--padding));
    // }
}

.body {
    position: relative;
}

.grid {
    position: absolute;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    height: 100%;
    display: grid;

    div+div {
        border-left: 1px solid;
    }
}