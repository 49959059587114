@import "~scss/imports";

.required {
  @extend %h4;
  margin: 10px 0 1rem;
  display: flex;
  align-items: center;
  line-height: 1;
  position: relative;
}

.requiredMarker {
  border: 1px solid;
  width: 18px;
  height: 17px;
  border-radius: 50%;
  margin-right: 0.5em;
  line-height: 1;
  flex-shrink: 0;
  position: relative;
}
.requiredMarkerFilled {
  composes: requiredMarker;
  background-color: currentColor;
}
.check {
  position: absolute;
  left: 3px;
  top: 0px;
}
