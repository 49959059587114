@import "~scss/imports";

.element {
  width: 100%;
}

input {
  font-family: "Good Sans";
  background-color: transparent;
  border: 1px solid var(--white);
  font-size: 16px;
  padding: 12px;
  width: 100%;
  color: inherit;
  border-radius: 0;

  &::placeholder {
    color: rgna(255, 255, 255, 0.5);
  }
}

.submitWrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.submit {
  background: transparent;
  margin-top: 0;
}
h4 {
  @extend %h4;
}
.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2em;
  .row {
    margin-bottom: 0;
  }
  & > * {
    width: calc(50% - 10px);
  }
  & > *:only-child {
    width: 100%;
  }
  @include breakpoint(md) {
    & > input {
      width: calc(50% - 1em);
    }
  }
}
.errorMsg {
  color: var(--red);
}
.errorMsg a,
.statusMsg {
  color: var(--peach);
}
.successMsg {
  color: var(--green);
}
.fieldWrap {
  display: flex;
  flex-direction: column;
}
.radioSet {
  display: flex;
  flex-direction: row;
}
.radioWithLabel {
  display: flex;
  & + .radioWithLabel {
    margin-left: 1rem;
  }
  label {
    padding-left: 40px;
    position: relative;
    font-family: "Good Sans";
    cursor: pointer;
  }
  label:before {
    content: "";
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 20px;
    border: 1px solid var(--white);
    border-radius: 50%;
    transition: 0.25s;
  }
  input:checked + label:before {
    background-color: var(--white);
  }
  input[type="radio"] {
    opacity: 0;
    height: 0;
    width: 0;
    position: absolute;
  }
}
.conditionalField {
  opacity: 0;
  transition: 0.5s;
  &:global(.enter-done) {
    opacity: 1;
  }
}
.intro {
  text-align: center;
  p {
    margin: 0 auto;
  }
  a,
  a:visited {
    color: var(--white);
  }
}
