@import "~scss/imports";

.label {
  @extend %h4;
  display: block;
  margin-bottom: 0.25rem;
}
.hidden {
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
}

.dropdown {
  text-transform: none;
}
