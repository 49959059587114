@import "~scss/imports";

.grid {
  margin: var(--padding) 0;
  display: flex;
  flex-direction: row;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
  grid-auto-rows: 1fr;
  border-top: 1px solid currentColor;
  border-left: 1px solid currentColor;

  input[type="radio"] {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
  }
  input:checked + .indicator {
    background-color: var(--black);
    :global(.bg--blue) &,
    :global(.bg--black) & {
      background-color: var(--white);
    }
  }

  & > label {
    border-bottom: 1px solid currentColor;
    border-right: 1px solid currentColor;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:after {
    content: "";
    width: 0;
    padding-bottom: 100%;
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }
  & > *:first-child {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }
  @media (min-width: 1200px) {
    grid-template-columns: repeat(auto-fill, minmax(10%, 1fr));
    max-width: none;
  }
}
.indicator {
  //   border: 1px solid var(--black);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
  cursor: pointer;

  svg {
    max-width: 80%;
    height: auto;
  }
}
