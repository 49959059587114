.questions {
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
}
.echeck {
  margin-top: 12px;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
}
.verified {
  visibility: hidden;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
}
.element {
  :global(.error) {
    color: var(--red);
    margin-top: 4px;
  }
}
