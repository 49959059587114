@import "~scss/imports";

.row {
  border-top: 1px solid;
  padding: 28px var(--padding);
  margin: 0 calc(-1 * var(--padding));
  &:last-child {
    border-bottom: 1px solid;
    margin-bottom: 6rem;
  }

  // &:last-child {
  //   border-bottom: 1px solid;
  // }
}
.heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  h3 {
    @extend %h3;
    text-align: left;
    margin: 0;
  }
  @include breakpoint(sm-max) {
    //flex-direction: column;
  }
}
.caret {
  margin-left: 1rem;
  transition: 0.3s;
  background-color: transparent;
  border: none;
  color: currentColor;
  flex-shrink: 0;
  svg {
    max-width: 25px;
    path {
      stroke: currentColor;
    }
  }
  // @include breakpoint(sm-max) {
  //   margin-left: 0;
  // }
}
.heading:global(.open) {
  .caret {
    transform: rotate(180deg);
  }
}

.inner {
  padding: var(--padding) 0;

  overflow: hidden;
  max-height: 0;
  transition: 1s;
  @extend %p;
  line-height: 1.375;

  &:global(.anim-enter-done) {
    max-height: 2000px;
  }
}
