@import "~scss/imports.scss";

.element {
  @extend %h4;
  position: fixed;
  margin: var(--padding) 0;
  z-index: 1000;
  a,
  a:visited,
  a:focus {
    color: currentColor;
    text-decoration: none;
    padding: 0 1rem;
    justify-content: center;
    display: flex;
    line-height: 1;
  }
  border: 1px solid;
  height: var(--posts-nav-height);
  width: 60%;

  margin-left: 59px;

  top: 0;
  left: var(--padding);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  @include breakpoint(md) {
    margin-left: 180px;
    height: 59px;
    max-width: 250px;
  }
  @include breakpoint(xl) {
    width: calc((100% - 2 * var(--padding)) / 3 - 179px);
    max-width: 287px;
  }
}
.buttons {
  display: flex;
  flex-direction: row;
  height: 100%;
}
.button {
  border-left: 1px solid currentColor;
  height: 100%;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    height: 100%;
    padding: 0.5rem 0;
    box-sizing: border-box;
  }

  box-sizing: content-box;
}
.all {
  text-align: center;
  width: 100%;
}
