@import "~scss/imports";

.label {
  @extend %h4;
  display: block;
  & + .label {
    margin-top: 2rem;
  }
}

.attribution {
  margin: 0;
  margin-bottom: 0.5rem;
  padding: 0;
  border: 0;
  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem;
  legend {
    @extend %h4;
    padding: 0;
  }
  .label {
    text-transform: none;
    & + .label {
      margin-top: 0;
    }
  }
}

.referenceAnswer {
  @extend %p;
  text-transform: none;
  line-height: 1.5;
  padding: var(--padding);
}

.successMessage {
  margin-top: var(--padding);
  // padding: var(--padding);
  // border: 1px solid;
  // color: var(--bg-color);
  // background-color: var(--current-color);
  @extend %h3;
}

.projectSelectNoProject {
  padding-bottom: 200px;
}
