@import "~scss/imports";

.input {
  @extend %textInput;
  border-color: currentColor;
  &:read-only {
    border: none;
    padding: 3px 0;
    font-weight: 700;
    &:active,
    &:focus {
      border: none;
    }
  }
}

.label {
  @extend %h4;
  display: block;
  margin-bottom: 0.25rem;
}
.prefix {
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;
  top: 10px;
  left: 8px;
  font-size: 1rem;
  line-height: 1;
  + .input {
    padding-left: 20px;
  }
}
.fieldWrap {
  position: relative;
}
