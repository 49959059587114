@import "~scss/imports";
.dropdown {
  width: 100%;
  max-height: 200px;
  overflow-y: scroll;
  position: absolute;
  border: 1px solid currentColor;
  top: 50px;
  z-index: 2000;
  display: none;
  button {
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid silver;
    padding: 0;
    margin: 0;
  }
  .item {
    span {
      pointer-events: none;
      color: var(--black) !important;
    }
    cursor: pointer;
    width: 100%;
    border: none;
    display: flex;
    justify-content: flex-start;
    background-color: var(--white);
    color: var(--black);

    :global(.bg--peach) & {
      background-color: var(--peach);
    }
    font-size: 1rem;
    padding: 0.5rem;
    display: flex;
    justify-content: flex-start;
    &:hover,
    &:focus {
      background-color: var(--blue);
      color: var(--white);
      span {
        color: var(--white) !important;
      }
    }

    :global(.bg--peach) {
      &:hover,
      &:focus {
        background-color: var(--white);
      }
    }
  }
}
.autocompleter {
  position: relative;

  &:focus-within .dropdown {
    display: block;
  }
  input[type="text"] {
    border-color: currentColor;

    &:focus {
      color: currentColor;
    }
  }
  @include breakpoint(lg) {
    width: calc(100% - 20px);
  }
}
.selectedStory {
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  a {
    text-decoration: none;
  }
  & + .selectedStory {
    border-top: 1px solid currentColor;
  }
}
.removeBtn {
  border: 1px solid var(--black);
  background-color: transparent;
  padding: 0.35rem 0.5rem;
  font-size: 0.7rem;
  line-height: 1;
  transition: 0.5s;
  cursor: pointer;

  &:hover {
    background-color: var(--white);
  }
}
.inspiration {
  margin: 1rem 0 2rem;

  .inspirationInner {
    border: 1px solid var(--black);
  }
  input:read-only {
    opacity: 0;
    height: 0;
    width: 0;
    margin: 0;
    padding: 0;
  }
}
.title {
  font-style: italic;
  margin: 0 0.5rem;
}
.name {
  font-weight: 800;
  margin: 0 0.5rem;
}
