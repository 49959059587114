@import "~scss/imports";

.element {
  fieldset {
    margin: 0;
    padding: 0;
    border: 0;
  }

  a {
    color: var(--blue);
  }
}

.heading {
  @extend %h2;
}

.label {
  @extend %h4;
  margin-bottom: 1rem;
}

.copy {
  @extend %p;
}

.blue {
  color: var(--blue);
}

.title {
  @extend %h3;
}
.deadline {
  padding-bottom: var(--padding);
}

.fieldset {
  padding: 10px;
  border-top: 1px solid var(--black);
  margin-left: calc(-1 * var(--padding));
  margin-right: calc(-1 * var(--padding));

  @media (min-width: 1200px) {
    padding: var(--padding);
  }
}

:global(.pad) {
  padding: 20px 0;
}

.pad {
  padding: 20px 0;
}

.inputDate {
  @extend %textInput;
}

.rightAlign {
  text-align: right;
}

.error {
  @extend %p;
  font-size: 14px;
  color: var(--red);
}

.indentText {
  @media (min-width: 1200px) {
    display: block;
    margin-left: 30px;
  }
}

.tooltipnote {
  @extend %h4;
  color: var(--blue);
  display: inline-block;
}

.success {
  padding: var(--padding);
}

.warning {
  color: var(--blue);
  @extend %h4;
}
