@import "~scss/imports";

.element {
  padding-bottom: 10vh;
  padding-top: 50vh;
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  //width: calc(100% - var(--padding) * 2);
}

.theme {
  color: var(--black);
  position: relative;
  margin-top: 10vh;
  position: relative;
  //overflow: hidden;

  width: 100%;
  min-height: 100px;

  margin-left: 0 !important;

  img {
    display: block;
    max-width: 100%;
    width: 100%;
    position: relative;
    //min-width: 500px;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0%);
  }
  @media (min-width: 500px) {
    width: auto;
    img {
      width: auto;
      transform: none;
      left: 0;
    }
  }
}

.themeHeading {
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  @extend %h3;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  //color: var(--white) !important;
  //padding: 0 !important;
  //background-color: var(--black);
  //border: 1px solid;
}
