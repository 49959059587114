@import "~scss/imports";
.container {
  height: calc(100vh - 2 * var(--padding));
  position: relative;
  //position: fixed;
  bottom: 0;
  top: var(--padding);
  left: var(--padding);
  width: calc(100% - 2 * var(--padding));
  display: flex;
  flex-direction: column;
}
.body {
  //position: absolute;
  left: 50%;
  //transform: translate(-50%, 0%);
  max-width: 44rem;
  margin-left: auto;
  margin-right: auto;
  min-height: 12rem;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 1200px) {
    margin-top: calc(2 * var(--padding));
  }
}
.svgWrap {
  position: relative;
  height: 100%;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.svg {
  position: absolute;
  width: 100%;
  height: 100%;
}

:global(.node) {
  //mix-blend-mode: multiply;
  opacity: 0.8;
}

.clusterValue {
  @extend %h2;
  fill: var(--blue);
  text-anchor: middle;
  font-size: 36px;
}
.clusterValueLabel {
  @extend %h3;
  fill: var(--blue);
  text-anchor: middle;
  font-size: 20px;
}
.clusterSubValue {
  @extend %h4;
  font-family: "Good Sans";
  fill: var(--black);
  text-anchor: middle;
}
.clusterLabel {
  @extend %h3;
  fill: var(--blue);
  text-anchor: middle;
}
