@import "~scss/imports";

.element {
  margin: 0 calc(-1 * var(--padding)) var(--padding);
  padding: 0 var(--padding) var(--padding);

  @extend %p;

  @include breakpoint(lg) {
    margin: 0;
    padding: 0;
    border: none;
    width: calc(33vw - 4 * var(--padding));
    max-width: 380px;
    position: absolute;
    top: 30%;
    left: calc(-2 * var(--padding));
    transform: translate(-100%, -50%);
  }
}
.flag {
  font-size: 10px;
  font-family: "Adieu";
  background: var(--blue);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  height: 26px;
  box-sizing: content-box;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 10px;
  &:after {
    content: "";
    border: transparent;
    border-top: 13px solid transparent;
    border-bottom: 13px solid transparent;
    border-left: 11px solid;

    width: 0;
    height: 0;
    position: absolute;
    right: -10.5px;
    top: 0;
  }
}
