@import "~scss/imports";

.name {
  margin: 1rem 0;
  font-family: "Adieu";
  @extend %h3;
  margin-bottom: 0.5rem;
  font-size: 1rem;

  @include breakpoint(sm) {
    font-size: 1.25rem;
  }
  @include breakpoint(lg) {
    font-size: 1rem;
  }
  @include breakpoint(xl) {
    font-size: 1.25rem;
  }
  @media (min-width: 1300px) {
    font-size: 1.35rem;
  }
}
.theme,
.age,
.region {
  font-family: "Good Sans";
  font-size: 12px;
  line-height: 1.5;
  @include breakpoint(md) {
    font-size: 0.88rem;
  }
}
