@import "~scss/imports";

.element {
  position: fixed;
  text-align: center;
  background-color: var(--black);
  border: 1px solid var(--black);

  width: 100%;
  margin: 0;
  top: 0;
  left: 0;
  padding: 10px;
  box-sizing: border-box;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Adieu";
  text-transform: uppercase;
  font-size: 12px;

  color: var(--white);
  a {
    color: var(--pink);
  }
  .content {
    padding: 0 10px;
  }
  @include breakpoint(md) {
    top: 0;
    font-size: 14px;
  }
  @include breakpoint(lg) {
    // position: fixed;
    top: 0;
    height: 50px;

    padding-top: 0;
    padding-bottom: 0;
  }
}
