@import "~scss/imports";

.element {
  background-color: var(--black);
  padding: var(--padding);
  min-height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.body {
  max-width: var(--max-text);
  margin-left: auto;
  margin-right: auto;
  margin-top: calc(var(--padding) * 2);
  margin-bottom: calc(var(--padding) * 2);
  position: relative;
  color: var(--black);
  padding: 0 var(--padding);
}

.heading {
  @extend %h2;
}

.text {
  @extend %p;
}
.shapes {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.shapes > * {
  position: absolute;
}

.shape0 {
  width: 60%;
  bottom: var(--padding);
  left: 10%;
}

.shape1 {
  width: 80%;
  min-width: 1000px;
  top: 45%;
  transform: translate(-60%, -45%);
  left: 50%;
}

.shape2 {
  right: var(--padding);
  top: var(--padding);
  width: 50%;
}

.cta {
  @extend %button;
  background-color: var(--green);
  margin-top: var(--padding);

  &:hover,
  &:focus {
    color: var(--green);
  }
}
