@import "~scss/imports";

.element {
  display: block;
  width: 100%;
  height: auto;
  max-width: 1500px;

  position: relative;

  &:after {
    background-color: var(--black);
    height: 1px;
    content: "";
    left: var(--padding);
    bottom: 0;
    position: absolute;
    width: calc(100% - var(--padding) * 2);
  }
}

.counties {
}
.county {
  transition: fill 1s, stroke 1s;
}
.defaultCounty {
  composes: county;
  fill: var(--green);
  stroke: var(--green);
  pointer-events: none;
}

.activeCounty {
  composes: county;
  fill: var(--black);
  stroke: var(--black);

  &:hover {
    fill: var(--yellow);
  }
}

.water {
  fill: var(--lilac);
  stroke: var(--lilac);
}

.marker {
  fill: var(--black);
  stroke: var(--yellow);

  &:hover {
    fill: var(--yellow);
  }
}

.label {
  @extend %h4;
  text-transform: uppercase;
  position: absolute;
  top: 70%;
}

.labelNy {
  composes: label;
  right: 10%;
}
.labelMi {
  composes: label;
  left: 0%;
}
@media (min-width: 375px) {
  .labelNy {
    right: 20%;
  }
  .labelMi {
    left: 15%;
  }
}
@media (min-width: 400px) {
  .labelNy {
    right: 20%;
  }
  .labelMi {
    left: 20%;
  }
}
@media (min-width: 500px) {
  .labelNy {
    right: 20%;
  }
  .labelMi {
    left: 25%;
  }
}
@media (min-width: 700px) {
  .labelNy {
    right: 25%;
  }
  .labelMi {
    left: 30%;
  }
}

.secondLine {
  @media (min-width: 768px) {
  }
}

.mapBlock {
  //position: absolute;
  //position: sticky;
  //top: var(--padding);
  padding: var(--padding);
  //height: calc(100vh - 2 * var(--padding));

  //overflow: hidden;
  margin-bottom: calc(var(--padding) * 4);
  max-width: 1400px;
  margin: 0 auto;
  padding-top: calc(var(--padding) * 4);
}
.map {
  //transform: rotate(9deg);
  //width: 115%;
  margin: 0 auto;
  height: auto;
  overflow: visible;
  width: 100%;
  max-height: 100vh;

  @media (min-width: 768px) {
    //transform: rotate(9deg) translate(20%, 0%);
    //width: 80%;
    //position: relative;
    //left: 20%;
  }
}

.steps {
  position: relative;
  pointer-events: none;
  padding-bottom: 30vh;
}

.step {
  min-height: 60vh;
  display: flex;
  align-items: center;
}

.stepInner {
  margin-right: auto;
  padding: 1em;
  background-color: white;
  border: 1px solid;
  position: relative;
  width: calc(100% - var(--padding) * 2);
  max-width: 400px;
  margin-left: var(--padding);

  @media (min-width: 1200px) {
    left: 10vw;

    margin-left: calc(var(--padding) * 3);
  }
}

.tooltip {
  &:before {
    border: 0 !important;
  }
  border: 1px solid var(--yellow) !important;
  border-radius: 50% !important;
}

.body {
  max-width: 28.75em;
  box-sizing: content-box;
  z-index: 2;

  margin: 0 auto;

  @media (min-width: 1000px) {
    position: absolute;

    right: 6vw;
  }

  @media (min-width: 1400px) {
    left: 800px;
  }
  @media (min-width: 1600px) {
  }
}
.heading {
  @extend %h2;
  margin-top: 0;
}
.stepHeading {
  @extend %h3;
  margin-top: 0;
}
.text {
  @extend %p;
  margin-bottom: 0;
}
