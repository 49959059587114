.el {
  color: currentColor;
  border: currentColor;
  background-color: transparent;
  border: 1px solid currentColor;
  cursor: pointer;
  padding: 0.5rem;
  position: absolute;
  right: calc(-0.5 * var(--padding));
  top: 50%;
  transform: translate(0, -50%);

  &.prev {
    right: auto;
    left: calc(-0.5 * var(--padding));
    z-index: 10;
  }
}

.slide {
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
