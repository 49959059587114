@import "scss/imports";

.item {
  width: 100%;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: var(--peach);
  font-size: 1rem;
  padding: 0.5rem;
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid var(--black);
  cursor: pointer;
  &:hover,
  &:focus {
    background-color: var(--white);
  }
}
.org {
  font-style: italic;
  margin: 0 0.5rem;
}
.title {
  font-weight: 800;
  margin: 0 0.5rem 0.5rem;
}
.providers {
  margin: 1rem 0 2rem;

  .providersInner {
    border: 1px solid var(--black);
  }
  input:read-only {
    opacity: 0;
    height: 0;
    width: 0;
    margin: 0;
    padding: 0;
  }
}
.removeBtn {
  border: 1px solid var(--black);
  background-color: transparent;
  padding: 0.35rem 0.5rem;
  font-size: 0.7rem;
  line-height: 1;
  transition: 0.5s;
  cursor: pointer;
  display: flex;

  justify-content: center;
  align-items: center;
  &:hover {
    background-color: var(--white);
  }
  margin-top: 1rem;
  @include breakpoint(sm) {
    flex-direction: row;
    margin-top: 0;
  }
}
.selectedProvider {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  a {
    text-decoration: none;
  }
  & + .selectedProvider {
    border-top: 1px solid currentColor;
  }

  @include breakpoint(sm) {
    flex-direction: row;
  }
}
