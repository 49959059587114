@import "~scss/imports";

.element {
    font-family: "Good Sans";
    font-size: 10px;
    position: fixed;
    top: 0;
    left: 68px;
    margin-top: calc(var(--padding));
    margin-left: var(--padding);
    z-index: 1000;
    transition: background-color 0.5s;

    display: none;

    @media (min-width: 375px) {
        display: block;
    }

    //change color on theme changes
    color: var(--black);

    a {
        color: var(--black);
        width: 100%;
        text-decoration: none;
        display: block;
        padding: 6px 20px 6px 6px;
        transition: 0.25s;

        &:hover {
            background: var(--black);
            color: var(--white);
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        border-top: 0;
        max-height: 0;
        overflow: hidden;
        transition: 0s;
    }

    &:hover ul {
        max-height: 400px;
        border: 1px solid;
        border-top: none;
        transition: 0.25s;
    }

    li+li {
        border-top: 1px solid;
    }

    &:global(.black) {
        color: var(--white);
        background: var(--black);

        ul {
            background: var(--black);
        }

        a {
            color: var(--white);

            &:hover {
                background: var(--white);
                color: var(--black);
            }
        }

        // background-color: var(--black);
    }

    &:global(.peach) {
        background-color: var(--peach);

        ul {
            background-color: var(--peach);
        }

        a {
            &:hover {
                color: var(--peach);
            }
        }
    }

    // &:global(.pale-peach) {
    //   a {
    //     &:hover {
    //       color: var(--pale-peach);
    //     }
    //   }
    // }
    &:global(.lilac) {
        background-color: var(--lilac);

        ul {
            background-color: var(--lilac);
        }
    }

    &:global(.pink) {
        background-color: var(--pink);

        ul {
            background-color: var(--pink);
        }
    }

    &:global(.white) {
        background-color: var(--white);

        ul {
            background-color: var(--white);
        }
    }

    &:global(.yellow) {
        background-color: var(--yellow);

        ul {
            background-color: var(--yellow);
        }

        a {
            &:hover {
                color: var(--yellow);
            }
        }
    }

    @include breakpoint(md) {
        left: calc(179px);

        a {
            padding: 12px 40px 12px 12px;
        }
    }
}

.current {
    width: 100%;
    height: 30px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 6px;
    padding-right: 6px;

    border: 1px solid;

    span {
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
    }

    @include breakpoint(lg) {
        height: 59px;
        padding: 0 40px 0 12px;
        box-sizing: border-box;

        span {
            right: 1em;
        }
    }
}