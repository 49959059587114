:root {
  --white: #fff;
  --black: #1b1a1f;
  --blue: #523dff;
  --purple: #ab1eba;
  --red: #ff3f44;
  --peach: #f9dacb;
  --pale-peach: #f9dacb;
  --pink: #fe8f9b;
  --lilac: #b4b5e4;
  --green: #00fab0;
  --yellow: #fefd38;
  --magenta: var(--purple);

  --provider-pink: var(--pink);
  --provider-yellow: var(--yellow);
  --provider-black: var(--black);
  --provider-black-b: var(--black);
  --provider-green: var(--green);
  --provider-peach: var(--peach);
  --provider-purple: var(--purple);
  --provider-lilac: var(--lilac);
  --provider-blue: var(--blue);
  --provider-white: var(--white);

  --padding: 20px;

  --size-h1: 28px;
  --size-h2: 24px;
  --size-h3: 20px;
  --size-quote: 24px;
  --size-step-number: 48px;

  --button-pad: 1em 1.5em;

  --max-text: 660px;

  --size-stories-form-heading: 36px;
  --posts-nav-height: 30px;

  @media (min-width: 375px) {
    --size-h1: 36px;
    --size-h2: 36px;
  }

  @media (min-width: 768px) {
    //--padding: 45px;
    --padding: 30px;

    --size-quote: 48px;
    --size-h1: 64px;
    --size-stories-form-heading: 64px;
  }

  @media (min-width: 1200px) {
    --size-h2: 48px;
    --size-h3: 24px;
    --size-quote: 72px;
    --size-step-number: 100px;

    --button-pad: 1.5em 3.25em;
  }
}
