@import "~scss/imports";
.element {
  border-top: 1px solid currentColor;
  margin: 0 calc(-1 * var(--padding));
  padding: var(--padding);

  ul {
    display: flex;
    flex-direction: column;
    padding: 0;
    @include breakpoint(md) {
      flex-direction: row;
      margin: var(--padding) calc(-1 * var(--padding));
    }
  }
  li {
    @include breakpoint(md) {
      margin: 0 var(--padding);
    }
  }
}
