@import "~scss/imports";

.Dropdown-control {
  font-family: "Good Sans";
  background-color: transparent;
  border: 1px solid;
  line-height: 20px;
  border-radius: 0;
  color: inherit;
  display: inline-block;
  width: 100%;
  // max-width: 350px;
  margin-right: 20px;
  // @extend %psmall;
  // text-transform: uppercase;
  cursor: pointer;

  @media (min-width: 1200px) {
    width: calc(100% - 20px);
  }
}

.Dropdown-root {
  // @media (min-width: 1200px) {
  //   top: 12px;
  // }

  &:focus,
  &:focus-within {
    .Dropdown-control {
      border-color: var(--blue);
    }
  }
}

.Dropdown-arrow {
  border-color: currentColor transparent transparent;
}

.is-open .Dropdown-arrow {
  border-color: currentColor transparent transparent;
}

.Dropdown-menu {
  background-color: var(--peach);
  border-color: var(--black);
  color: var(--black);
  @extend %p;
}

.Dropdown-option {
  color: var(--black);
}

.Dropdown-disabled {
  cursor: initial;
  border: none;
  padding-left: 0;
  .Dropdown-arrow {
    display: none;
  }
  .Dropdown-placeholder {
    font-weight: 700;
  }
}
