@import "imports";
$desk: 1024px;

.element {
    background-color: var(--white);

    &:global(.bg--black),
    &:global(.bg--blue) {
        color: var(--white);
    }
}

.headingText {
    max-width: 400px;
}

.bannerTop {
    @include breakpoint(md) {
        // .columns {
        //   padding-top: calc(var(--padding) + 85px);
        // }
    }

    @include breakpoint(lg) {

        // .columns {
        //   padding-top: calc(var(--padding) + 50px);
        // }
        :global(.logo),
        :global(.logo-backing),
        :global(.nav-trigger) {
            top: calc(var(--padding) + 50px);
        }
    }
}

.columns {
    padding: var(--padding);

    .column_inner {
        border: 1px solid;
        display: flex;
        flex-direction: row;
        min-height: calc(100vh - var(--padding) * 2);
        width: 100%;
    }

    @include breakpoint(sm-max) {
        padding: var(--padding);

        .column_inner {
            flex-direction: column;
        }
    }
}

.sidebar {
    @media (min-width: $desk) {
        width: calc(100% / 3);
        flex-shrink: 0;
        height: 100%;
        position: relative;
        max-width: 465px;
        // max-height: calc(100vh - var(--padding));
        // overflow: auto;
    }
}

.content {
    width: 100%;
    flex-grow: 0;

    z-index: 5;

    @media (min-width: $desk) {
        border-left: 1px solid;
    }

    .heading {
        @extend %h1;
    }

    .sectionTitle {
        @extend %h3;
    }

    // h1 {
    //   @extend %h1;
    // }
    // p,
    // ul {
    //   font-size: 1rem;
    //   line-height: 1.375;
    // }
    // ul {
    //   padding: 0 1rem;
    //   margin: 0;
    //   margin-top: 26px;
    // }
    // h3 {
    //   @extend %h3;
    //   margin-top: 48px;
    // }
    // p {
    //   @extend %p;
    // }
}

.contentOverflowFix {
    composes: content;
    overflow-x: hidden;
    overflow-y: hidden;
}

.inner {
    padding: calc(var(--padding) * 2) var(--padding) var(--padding);

    @media (min-width: $desk) {
        padding-top: var(--padding);
    }
}

.shape {
    margin-top: 100px;
    display: none;
    //position: fixed;
    width: 33%;
    max-width: 500px;
    z-index: 1;
    padding: 2rem;
    box-sizing: border-box;

    svg {
        width: 100%;
        height: auto;
    }

    @include breakpoint(lg) {
        display: block;
    }
}

.shape.overflow {
    width: 135%;
    left: -15%;
    position: absolute;
}