@import "~scss/imports";
.element {
  ul,
  ol {
    line-height: 1.3;
    li {
      @extend %p;
      margin-top: 2em;
    }
  }
}
